import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../src/UserContext';
import LoginStep from './AdvisorLoginStep';
import RegisterStep from './AdvisorRegisterStep';
import UpgradeStep from './AdvisorUpgradeStep';

export default function AccountCreationModal({ isOpen, closeModal, currentStep }) {
    const [step, setStep] = useState(null); // Internal state for the current step
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userType, setUserType] = useState('');
    const [formError, setFormError] = useState('');
    const { setUserInfo, refreshUserInfo } = useContext(UserContext); // Use refreshUserInfo from context
    const apiUrl = process.env.REACT_APP_API_URL || '';

    // Sync step with the currentStep prop when modal is opened
    useEffect(() => {
        if (isOpen) {
            setStep(currentStep); // Update the internal step state when modal opens
        }
    }, [isOpen, currentStep]);

    const validatePassword = (password) => {
        const hasLetters = /[a-zA-Z]/.test(password);
        const hasNumbers = /\d/.test(password);
        return password.length >= 6 && hasLetters && hasNumbers;
    };

    const handleRegister = async () => {
        if (!validatePassword(password)) {
            setFormError('Password must be at least 6 characters and contain both letters and numbers.');
            return;
        }

        // Attempt to register the user
        const registerResponse = await fetch(`${apiUrl}/register`, {
            method: 'POST',
            body: JSON.stringify({ email, password, userType }),
            headers: {'Content-Type':'application/json'},
        });

        if (registerResponse.ok) {
            // Automatically log the user in upon successful registration
            const loginResponse = await fetch(`${apiUrl}/login`, {
                method: 'POST',
                body: JSON.stringify({ email, password }),
                headers: {'Content-Type':'application/json'},
                credentials: 'include', // Important for session-based authentication
            });
            if (loginResponse.ok) {
                await refreshUserInfo(); // Fetch and update user info after registration
                setStep(3); // Proceed to upgrade step
            } else {
                setFormError('Login failed after registration. Please try logging in manually.');
            }
        } else {
            const errorData = await registerResponse.json();
            setFormError(`Registration failed: ${errorData.message}`);
        }
    };

    const handleLogin = async () => {
        try {
            const loginResponse = await fetch(`${apiUrl}/login`, {
                method: 'POST',
                body: JSON.stringify({ email, password }),
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
            });
    
            if (loginResponse.ok) {
                await refreshUserInfo(); // Fetch and update user info after login
                
                // Fetch user info directly to avoid potential context timing issues
                const userResponse = await fetch(`${apiUrl}/profile`, { credentials: 'include' });
                if (userResponse.ok) {
                    const updatedUserInfo = await userResponse.json();
    
                    // Determine the next step based on user tier
                    if (updatedUserInfo?.tier === 'free') {
                        setStep(3); // Proceed to upgrade step for free-tier users
                    } else if (updatedUserInfo?.tier === 'advisor') {
                        closeModal(); // Close the modal for advisor-tier users
                    }
                }
            } else {
                setFormError('Login failed. Please check your credentials and try again.');
            }
        } catch (error) {
            setFormError('Something went wrong. Please try again later.');
        }
    };    
    

    const renderStep = () => {
        if (step === null) {
            return null; // Don't render content until the step is initialized
        }
        
        switch (step) {
            case 1:
                return (
                    <RegisterStep
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        setPassword={setPassword}
                        userType={userType}
                        setUserType={setUserType}
                        handleRegister={handleRegister}
                        setCurrentStep={setStep}
                        formError={formError}
                    />
                );
            case 2:
                return (
                    <LoginStep
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        setPassword={setPassword}
                        handleLogin={handleLogin}
                        setCurrentStep={setStep}
                        formError={formError}
                    />
                );
            case 3:
                return <UpgradeStep closeModal={closeModal} />;
            default:
                return null;
        }
    };

    if (!isOpen) return null;

    return (
        <div className="collegeadvisor-modal">
            <div className="collegeadvisor-modal-content">
                <button className="collegeadvisor-close-button" onClick={closeModal} aria-label="Close modal">
                    &times;
                </button>
                {renderStep()}
            </div>
        </div>
    );    
}
