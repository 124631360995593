import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';
import useDocumentTitle from '../useDocumentTitle';

export default function CollegeBudgetingPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const INSTNM = queryParams.get('INSTNM');
    const UNITID = queryParams.get('UNITID');

    useDocumentTitle(`${INSTNM} Budget Plan`);

    const apiUrl = process.env.REACT_APP_API_URL || '';

    const { userInfo } = useContext(UserContext);

    const [familyInfo, setFamilyInfo] = useState(null);
    const [collegeData, setCollegeData] = useState({});
    const [topFields, setTopFields] = useState([]);

    const [tuition, setTuition] = useState(null);
    const [totalCosts, setTotalCosts] = useState(null);
    const [netCosts, setNetCosts] = useState(null);
    const [scholarshipNeed, setScholarshipNeed] = useState(null);
    const [scholarshipMerit, setScholarshipMerit] = useState(null);
    const [totalScholarships, setTotalScholarships] = useState(null);
    const [roomBoard, setRoomBoard] = useState(null);
    const [bookSupplies, setBooksSupplies] = useState(null);
    const [otherExpenses, setOtherExpenses] = useState(null);
    const [calculatedTotalCost, setCalculatedTotalCost] = useState(null);
    const [calculatedNetCost, setCalculatedNetCost] = useState(null);
    const [avgScholarship, setAvgScholarship] = useState(null);
    const [calculatedNeed, setCalculatedNeed] = useState(null);
    const [shortfall, setShortfall] = useState(null);

    const [livingArrangement, setLivingArrangement] = useState("on-campus");
    const [tuitionType, setTuitionType] = useState(null);

    const [sai, setSAI] = useState(null);
    const [pell, setPell] = useState(null);
    const [familyContribution, setFamilyContribution] = useState(null);
    const [workStudy, setWorkStudy] = useState(null);
    const [maxSubsidizedLoans, setMaxSubsidizedLoans] = useState({
        firstYear: null,
        secondYear: null,
        thirdYear: null,
        fourthYear: null,
    });
    const [maxNeedScholarships, setMaxNeedScholarships] = useState(null);

    const [loans, setLoans] = useState({
        firstYear: { subsidized: 0, unsubsidized: 0, unsubsidizedAccrued: 0, parentPlus: 0, parentPlusAccrued: 0 },
        secondYear: { subsidized: 0, unsubsidized: 0, unsubsidizedAccrued: 0, parentPlus: 0, parentPlusAccrued: 0 },
        thirdYear: { subsidized: 0, unsubsidized: 0, unsubsidizedAccrued: 0, parentPlus: 0, parentPlusAccrued: 0 },
        fourthYear: { subsidized: 0, unsubsidized: 0, unsubsidizedAccrued: 0, parentPlus: 0, parentPlusAccrued: 0 },
    });
    
    const [loanSummary, setLoanSummary] = useState({
        totalSubsidized: 0,
        totalUnsubsidized: 0,
        totalParentPlus: 0,
        totalUnsubsidizedAccrued: 0,
        totalParentPlusAccrued: 0,
    });
    const [loanPayments, setLoanPayments] = useState({
        subsidizedPayment: 0,
        unsubsidizedPayment: 0,
        parentPlusPayment: 0,
    });
    

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showSupportMessage, setShowSupportMessage] = useState(false);

    const [expandedSections, setExpandedSections] = useState({});

    const toggleSection = (sectionName) => {
        setExpandedSections((prev) => ({
            ...prev,
            [sectionName]: !prev[sectionName], // Toggle the state of the specific section
        }));
    };

    const undergraduateLoanLimits = {
        firstYear: {
            totalLimit: 5500,
            subsidizedLimit: 3500,
        },
        secondYear: {
            totalLimit: 6500,
            subsidizedLimit: 4500,
        },
        thirdYear: {
            totalLimit: 7500,
            subsidizedLimit: 5500,
        },
        fourthYear: {
            totalLimit: 7500,
            subsidizedLimit: 5500,
        },
    };

    const studentLoanInterestRate = .0653;
    const parentPlusLoanInterestRate = .0908;

    useEffect(() => {
        if (!userInfo) {
            navigate('/collegeadvisor');
        }
    }, [userInfo, navigate]);

    useEffect(() => {
        const fetchFamilyInfo = async () => {
            try {
                const response = await fetch(`${apiUrl}/family-info`, {
                    credentials: 'include', // Include cookies for authentication
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch family info');
                }
                const data = await response.json();
                setFamilyInfo(data);
            } catch (err) {
                console.error('Error fetching family info:', err);
                setError(err.message);
            }
        };

        const fetchCollegeData = async () => {
            try {
                const response = await fetch(`${apiUrl}/college/${INSTNM}/${UNITID}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch college data');
                }
                const data = await response.json();
                setCollegeData(data);
            } catch (err) {
                console.error('Error fetching college data:', err);
                setError(err.message);
            }
        };

        const fetchFieldsOfStudy = async () => {
            try {
                const response = await fetch(`${apiUrl}/fields-of-study?UNITID=${UNITID}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch fields of study');
                }
                const data = await response.json();
                setTopFields(data.results); // Update the state with the new data
            } catch (err) {
                console.error('Error fetching fields of study:', err);
                setError(err.message);
            }
        };        

        const fetchData = async () => {
            setLoading(true);
            await Promise.all([fetchFamilyInfo(), fetchCollegeData(), fetchFieldsOfStudy()]);
            setLoading(false);
        };

        fetchData();
    }, [INSTNM, UNITID, apiUrl]);

    useEffect(() => {
        if (collegeData && collegeData.PREDDEG !== undefined) {
            setShowSupportMessage(collegeData.PREDDEG !== 3);
        }
    }, [collegeData]);

    useEffect(() => {
        if (familyInfo && collegeData && Object.keys(collegeData).length > 0) {
            const calculatedTuition =
                familyInfo.studentState === collegeData.STABBR
                    ? collegeData.TUITIONFEE_IN
                    : collegeData.TUITIONFEE_OUT;
    
            setTuition(calculatedTuition);
            setTotalCosts(collegeData.COSTT4_A || collegeData.COSTT4_P);
            setNetCosts(collegeData.NPT4_PRIV || collegeData.NPT4_PUB);
            setBooksSupplies(collegeData.BOOKSUPPLY);

            if (totalCosts && netCosts) {
                setAvgScholarship(totalCosts - netCosts);
            }

            setSAI(familyInfo.sai);
            setFamilyContribution(familyInfo.savingsContribution);
    
            // Handle room and board + other expenses logic
            if (collegeData.ROOMBOARD_ON) {
                setRoomBoard(collegeData.ROOMBOARD_ON || 0);
                setOtherExpenses(collegeData.OTHEREXPENSE_ON || 0);
                setLivingArrangement("on-campus");
            } else {
                setLivingArrangement("at-home");
                setRoomBoard(0);
                setOtherExpenses(collegeData.OTHEREXPENSE_FAM || 0);
            }
    
            if (
                collegeData.TUITIONFEE_IN &&
                collegeData.TUITIONFEE_IN === collegeData.TUITIONFEE_OUT
            ) {
                setTuitionType("Tuition is the same for in-state and out of state students.");
            } else if (
                collegeData.TUITIONFEE_IN &&
                collegeData.TUITIONFEE_IN !== collegeData.TUITIONFEE_OUT
            ) {
                setTuitionType("Tuition varies for in-state and out of state students.");
            } else {
                setTuitionType("");
            }
        }
    }, [familyInfo, collegeData]);
    

    useEffect(() => {
        const calculatedTotalCost =
            (tuition || 0) + (roomBoard || 0) + (otherExpenses || 0) + (bookSupplies || 0);
        setCalculatedTotalCost(calculatedTotalCost);
    }, [tuition, roomBoard, otherExpenses, bookSupplies]);
    
    useEffect(() => {
        const totalScholarships = Number(pell || 0) + Number(scholarshipNeed || 0) + Number(scholarshipMerit || 0);
        setTotalScholarships(totalScholarships);
        
        const calculatedNetCost = Math.max(0, (calculatedTotalCost || 0) - (pell || 0) - (scholarshipNeed || 0) - (scholarshipMerit || 0));
        setCalculatedNetCost(calculatedNetCost);
        
        const calculatedShortfall = Math.max(0, calculatedNetCost - (familyContribution || 0) - (workStudy || 0));
        setShortfall(calculatedShortfall);

    }, [calculatedTotalCost, pell, scholarshipNeed, scholarshipMerit, familyContribution, workStudy]);    

    useEffect(() => {
        const calculatedNeed = Math.max(0, (calculatedTotalCost || 0) - (sai || 0));
        setCalculatedNeed(calculatedNeed);

        const calculatedPell = Math.min(calculatedNeed, familyInfo?.pellGrant || 0);
        setPell(calculatedPell);

        const firstYearSubsidizedLoan = Math.min(
            calculatedNeed - calculatedPell,
            undergraduateLoanLimits.firstYear.subsidizedLimit
        );
        const secondYearSubsidizedLoan = Math.min(
            calculatedNeed - calculatedPell,
            undergraduateLoanLimits.secondYear.subsidizedLimit
        );
        const thirdYearSubsidizedLoan = Math.min(
            calculatedNeed - calculatedPell,
            undergraduateLoanLimits.thirdYear.subsidizedLimit
        );
        const fourthYearSubsidizedLoan = Math.min(
            calculatedNeed - calculatedPell,
            undergraduateLoanLimits.fourthYear.subsidizedLimit
        );
    
        setMaxSubsidizedLoans({
            firstYear: firstYearSubsidizedLoan,
            secondYear: secondYearSubsidizedLoan,
            thirdYear: thirdYearSubsidizedLoan,
            fourthYear: fourthYearSubsidizedLoan,
        });

        const calculatedMaxNeedScholarships = calculatedNeed - calculatedPell - firstYearSubsidizedLoan;
        setMaxNeedScholarships(calculatedMaxNeedScholarships);

    }, [calculatedTotalCost, sai, familyInfo]);

    useEffect(() => {
        const calculateLoans = () => {
            const yearsToAccrue = [4, 3, 2, 1]; // Years of interest accrual for each year
            const newLoans = {};
    
            ['firstYear', 'secondYear', 'thirdYear', 'fourthYear'].forEach((year, index) => {
                const annualShortfall = shortfall; // Assuming equal shortfall per year
                const maxSubsidized = maxSubsidizedLoans[year] || 0;
                const maxUnsubsidized =
                    undergraduateLoanLimits[year]?.totalLimit - maxSubsidized || 0;
    
                const subsidizedLoan = Math.min(annualShortfall, maxSubsidized);
                const unsubsidizedLoan = Math.min(
                    annualShortfall - subsidizedLoan,
                    maxUnsubsidized
                );
                const parentPlusLoan = Math.max(
                    0,
                    annualShortfall - subsidizedLoan - unsubsidizedLoan
                );
    
                // Calculate accrued interest for unsubsidized loans
                const dailyUnsubsidizedInterest = (unsubsidizedLoan * studentLoanInterestRate) / 365;
                const totalUnsubsidizedAccrued = dailyUnsubsidizedInterest * yearsToAccrue[index] * 365;
    
                // Calculate accrued interest for Parent PLUS loans
                const dailyParentPlusInterest = (parentPlusLoan * parentPlusLoanInterestRate) / 365;
                const totalParentPlusAccrued = dailyParentPlusInterest * yearsToAccrue[index] * 365;
    
                newLoans[year] = {
                    subsidized: subsidizedLoan,
                    unsubsidized: unsubsidizedLoan,
                    unsubsidizedAccrued: totalUnsubsidizedAccrued,
                    parentPlus: parentPlusLoan,
                    parentPlusAccrued: totalParentPlusAccrued,
                };
            });
    
            setLoans(newLoans);
        };
    
        if (shortfall !== null) {
            calculateLoans();
        }
    }, [shortfall, maxSubsidizedLoans, studentLoanInterestRate, parentPlusLoanInterestRate]);    

    useEffect(() => {
        if (loans) {
            const totalSubsidized = Object.values(loans).reduce((acc, year) => acc + (year.subsidized || 0), 0);
            const totalUnsubsidized = Object.values(loans).reduce((acc, year) => acc + (year.unsubsidized || 0), 0);
            const totalParentPlus = Object.values(loans).reduce((acc, year) => acc + (year.parentPlus || 0), 0);
            const totalUnsubsidizedAccrued = Object.values(loans).reduce(
                (acc, year) => acc + (year.unsubsidizedAccrued || 0),
                0
            );
            const totalParentPlusAccrued = Object.values(loans).reduce(
                (acc, year) => acc + (year.parentPlusAccrued || 0),
                0
            );
    
            setLoanSummary({
                totalSubsidized,
                totalUnsubsidized,
                totalParentPlus,
                totalUnsubsidizedAccrued,
                totalParentPlusAccrued,
            });
        }
    }, [loans]);
    

    useEffect(() => {
        const calculateMonthlyPayment = (principal, annualRate, years = 10) => {
            if (principal <= 0 || annualRate <= 0) return 0; // Avoid invalid calculations
    
            const monthlyRate = annualRate / 12; // Monthly interest rate
            const totalPayments = years * 12; // Total number of payments
    
            return (monthlyRate * principal) / (1 - Math.pow(1 + monthlyRate, -totalPayments));
        };
    
        if (
            loanSummary.totalSubsidized === 0 &&
            loanSummary.totalUnsubsidized === 0 &&
            loanSummary.totalParentPlus === 0
        ) {
            setLoanPayments({
                subsidizedPayment: 0,
                unsubsidizedPayment: 0,
                parentPlusPayment: 0,
            });
        } else {
            // Include accrued interest in the principal for payment calculations
            const unsubsidizedPrincipal =
                loanSummary.totalUnsubsidized + loanSummary.totalUnsubsidizedAccrued;
            const parentPlusPrincipal =
                loanSummary.totalParentPlus + loanSummary.totalParentPlusAccrued;
    
            const subsidizedPayment = calculateMonthlyPayment(
                loanSummary.totalSubsidized,
                studentLoanInterestRate
            );
            const unsubsidizedPayment = calculateMonthlyPayment(
                unsubsidizedPrincipal,
                studentLoanInterestRate
            );
            const parentPlusPayment = calculateMonthlyPayment(
                parentPlusPrincipal,
                parentPlusLoanInterestRate
            );
    
            setLoanPayments({
                subsidizedPayment,
                unsubsidizedPayment,
                parentPlusPayment,
            });
        }
    }, [
        loanSummary.totalSubsidized,
        loanSummary.totalUnsubsidized,
        loanSummary.totalParentPlus,
        loanSummary.totalUnsubsidizedAccrued,
        loanSummary.totalParentPlusAccrued,
        studentLoanInterestRate,
        parentPlusLoanInterestRate,
    ]);

    const handleLivingArrangementChange = (event) => {
        const selectedOption = event.target.value;
        setLivingArrangement(selectedOption);

        if (selectedOption === "on-campus") {
            setRoomBoard(collegeData.ROOMBOARD_ON);
            setOtherExpenses(collegeData.OTHEREXPENSE_ON);
        } else if (selectedOption === "off-campus") {
            setRoomBoard(collegeData.ROOMBOARD_OFF);
            setOtherExpenses(collegeData.OTHEREXPENSE_OFF);
        } else if (selectedOption === "at-home") {
            setRoomBoard(0);
            setOtherExpenses(collegeData.OTHEREXPENSE_FAM);
        }
    };

    // Helper function to calculate PTI
    const calculatePTI = (annualIncome, totalPayment) => {
        if (totalPayment <= 0) return 'N/A'; // Avoid division by zero or invalid calculations
        const monthlyIncome = annualIncome / 12;
        const pti = (totalPayment / monthlyIncome).toFixed(2);
        return pti; // PTI as a ratio
    };

    const formatCurrency = (value) => {
        if (value === '' || value === '-' || isNaN(Number(value))) return value; // Allow incomplete states
        const numberValue = Number(value);
        const prefix = numberValue < 0 ? '-' : ''; // Keep negative sign
        return `${prefix}$${Math.abs(numberValue).toLocaleString()}`; // Format as currency
    }; 
    
    function formatNumberAsPercentage(number, decimalPlaces = 0) {
        if (number !== null && number !== undefined) {
            return `${(number * 100).toFixed(decimalPlaces)}%`;
        }
        return "N/A";
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (showSupportMessage) {
        return (
            <div className="collegebudgeting-main">
                <h1>{INSTNM} Budget Plan</h1>
                <h3>Support for associate's degrees and trade schools is coming soon! 👷</h3>
            </div>
        );
    }
    

    return (
        <>
            <h1 className="collegebudgeting-h1">{INSTNM} Budget Plan</h1>
            <div className="collegebudgeting-main">
                <div>
                    <div className="collegebudgeting-cost-container">
                        <div className="collegebudgeting-header">
                            <h2 className="collegebudgeting-section-header">Annual Cost Estimate</h2>
                            <button
                                className="toggle-button"
                                onClick={() => toggleSection('annualCost')}
                            >
                                {expandedSections['annualCost'] ? '+' : '-'}
                            </button>
                        </div>
                        {!expandedSections['annualCost'] && (
                            <div className="collegebudgeting-content">
                                <div className="collegebudgeting-cost-category">
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">
                                                1. Tuition
                                            </div>
                                            <small>{tuitionType}</small>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue">
                                            {tuition !== null ? `$${tuition.toLocaleString()}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="collegebudgeting-cost-category">
                                    <label className="collegebudgeting-label">2. Where does the student plan to live?</label>
                                        <select
                                                className="collegebudgeting-dropdown"
                                                value={livingArrangement}
                                                onChange={handleLivingArrangementChange}
                                            >
                                                <option value="on-campus">On Campus</option>
                                                <option value="off-campus">Off Campus</option>
                                                <option value="at-home">At Home</option>
                                        </select>
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">Room and Board</div>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue">
                                            {roomBoard !== null ? `$${roomBoard.toLocaleString()}` : 'N/A'}
                                        </div>
                                    </div>
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">Other Expenses</div>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue">
                                            {otherExpenses !== null ? `$${otherExpenses.toLocaleString()}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="collegebudgeting-cost-category">
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">
                                                3. Books and Supplies
                                            </div>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue">
                                            {tuition !== null ? `$${bookSupplies.toLocaleString()}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                                <div className="collegebudgeting-cost-category-total">
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle-total">Est. Total Costs</div>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue-total">
                                            {calculatedTotalCost !== null ? `$${calculatedTotalCost.toLocaleString()}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                    </div>
                </div>
                <div>
                    <div className="collegebudgeting-cost-container">
                        <div className="collegebudgeting-header">
                            <h2 className="collegebudgeting-section-header">Estimated Aid</h2>
                            <button
                                className="toggle-button"
                                onClick={() => toggleSection('annualAid')}
                            >
                                {expandedSections['annualAid'] ? '+' : '-'}
                            </button>
                        </div>
                        {!expandedSections['annualAid'] && (
                            <div className="collegebudgeting-content">
                                <div className="collegebudgeting-cost-category">
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">Est. Total Costs</div>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue">
                                            {calculatedTotalCost !== null ? `$${calculatedTotalCost.toLocaleString()}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="collegebudgeting-cost-category">
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">Student Aid Index (SAI)</div>
                                            <small>Student aid index is used to determine your eligibility for certain federal grants and loans.</small>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue-scholarship">
                                            {sai !== null ? `$${sai.toLocaleString()}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="collegebudgeting-cost-category">
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle-total">Est. Total Need</div>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue-total">
                                            {calculatedNeed !== null ? `$${calculatedNeed.toLocaleString()}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="collegebudgeting-cost-category">
                            <div className="collegebudgeting-cost-subcategory">
                                <div className="collegebudgeting-title-container">
                                    <div className="collegebudgeting-cost-itemtitle-total">Est. Pell Grants</div>
                                    <small>Pell grants do not need to be repaid.</small>
                                </div>
                                <div className="collegebudgeting-cost-itemvalue-scholarship">
                                    {pell !== null ? `$${pell.toLocaleString()}` : 'N/A'}
                                </div>
                            </div>
                            <div className="collegebudgeting-cost-subcategory">
                                <div className="collegebudgeting-title-container">
                                    <div className="collegebudgeting-cost-itemtitle-total">Max Year 1 Subsidized Loans</div>
                                    <small>Subsidized loans do not accrue interest during school but must be repaid.</small>
                                </div>
                                <div className="collegebudgeting-cost-itemvalue-scholarship">
                                    {maxSubsidizedLoans.firstYear !== null ? `$${maxSubsidizedLoans.firstYear.toLocaleString()}` : 'N/A'}
                                </div>
                            </div>
                            <div className="collegebudgeting-cost-subcategory">
                                <div className="collegebudgeting-title-container">
                                    <div className="collegebudgeting-cost-itemtitle-total">Max Need-Based Scholarships</div>
                                    <small>Need-based scholarships are offered by some universities to assist with financial need.</small>
                                </div>
                                <div className="collegebudgeting-cost-itemvalue-scholarship">
                                    {maxNeedScholarships !== null ? `$${maxNeedScholarships.toLocaleString()}` : 'N/A'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="collegebudgeting-cost-container">
                        <div className="collegebudgeting-header">
                            <h2 className="collegebudgeting-section-header">Payment Sources</h2>
                            <button
                                className="toggle-button"
                                onClick={() => toggleSection('annualPayments')}
                            >
                                {expandedSections['annualPayments'] ? '+' : '-'}
                            </button>
                        </div>
                        {!expandedSections['annualPayments'] && (
                            <div className="collegebudgeting-content">
                                <div className="collegebudgeting-cost-category">
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">
                                                Est. Pell Grant
                                            </div>
                                            <small>Pell grants do not need to be repaid.</small>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue-scholarship">
                                            {pell !== null ? `$${pell.toLocaleString()}` : 'N/A'}
                                        </div>
                                    </div>
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">Need-Based Scholarships</div>
                                            <small></small>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue-scholarship">
                                            <input
                                                type="text"
                                                value={scholarshipNeed === null || scholarshipNeed === '' ? '' : formatCurrency(scholarshipNeed)}
                                                onChange={(e) => {
                                                    const rawValue = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                                                    if (rawValue === '' || Number(rawValue) >= 0) {
                                                        setScholarshipNeed(rawValue); // Update raw value
                                                    }
                                                }}
                                                onFocus={(e) => {
                                                    if (scholarshipNeed === 0) {
                                                        setScholarshipNeed(''); // Clear input if current value is 0
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    if (e.target.value === '') {
                                                        setScholarshipNeed(0); // Reset to 0 if input is left empty
                                                    }
                                                }}
                                                placeholder="$0"
                                                className="collegebudgeting-scholarship-input"
                                            />
                                        </div>
                                    </div>
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">Merit Scholarships</div>
                                            <small></small>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue-scholarship">
                                            <input
                                                type="text"
                                                value={scholarshipMerit === null || scholarshipMerit === '' ? '' : formatCurrency(scholarshipMerit)}
                                                onChange={(e) => {
                                                    const rawValue = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                                                    if (rawValue === '' || Number(rawValue) >= 0) {
                                                        setScholarshipMerit(rawValue); // Update raw value
                                                    }
                                                }}
                                                onFocus={(e) => {
                                                    if (scholarshipMerit === 0) {
                                                        setScholarshipMerit(''); // Clear input if current value is 0
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    if (e.target.value === '') {
                                                        setScholarshipMerit(0); // Reset to 0 if input is left empty
                                                    }
                                                }}
                                                placeholder="$0"
                                                className="collegebudgeting-scholarship-input"
                                            />
                                        </div>
                                    </div>
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle-total">
                                                Total Scholarships and Grants
                                            </div>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue-scholarship">
                                            {totalScholarships !== null ? `$${totalScholarships.toLocaleString()}` : 'N/A'}
                                        </div>
                                    </div>
                                    {avgScholarship && (
                                        <p className="collegebudgeting-cost-hint">The average student at {INSTNM} receives <strong>{formatCurrency(avgScholarship)}</strong> in grants and scholarships.</p>
                                    )}
                                </div>
                                <div className="collegebudgeting-cost-category">
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle-total">Net Cost After Scholarships</div>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue-total">
                                            {calculatedTotalCost !== null ? `$${calculatedNetCost.toLocaleString()}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="collegebudgeting-cost-category">
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">
                                                Family Contributions
                                            </div>
                                            <small>Funds provided from parents or other family members</small>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue-scholarship">
                                            {familyContribution !== null ? `$${familyContribution.toLocaleString()}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="collegebudgeting-cost-category">
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">
                                                Work Study
                                            </div>
                                            <small>Work study provides part-time jobs to students with financial need.</small>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue-scholarship">
                                            <input
                                                type="text"
                                                value={workStudy === null || workStudy === '' ? '' : formatCurrency(workStudy)} // Format value if present
                                                onChange={(e) => {
                                                    const rawValue = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                                    if (rawValue === '' || Number(rawValue) >= 0) {
                                                        setWorkStudy(rawValue); // Store raw numeric value
                                                    }
                                                }}
                                                onFocus={(e) => {
                                                    if (workStudy === 0) {
                                                        setWorkStudy(''); // Clear the input if the current value is `0`
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    if (e.target.value === '') {
                                                        setWorkStudy(0); // Reset to `0` if the input is empty
                                                    }
                                                }}
                                                placeholder="$0"
                                                className="collegebudgeting-scholarship-input"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                                <div className="collegebudgeting-cost-category">
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle-total">Est. Annual Shortfall</div>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue-total">
                                            {shortfall !== null ? `$${shortfall.toLocaleString()}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                    </div>
                </div>
                <div>
                    <div className="collegebudgeting-cost-container">
                        <div className="collegebudgeting-header">
                            <h2 className="collegebudgeting-section-header">Estimated Total Loans</h2>
                            <button
                                className="toggle-button"
                                onClick={() => toggleSection('totalLoans')}
                            >
                                {expandedSections['totalLoans'] ? '+' : '-'}
                            </button>
                        </div>
                        {!expandedSections['totalLoans'] && (
                            <div className="collegebudgeting-content">
                                <div className="collegebudgeting-cost-category">
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle-total">Est. Annual Shortfall</div>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue-total">
                                            {shortfall !== null ? `$${shortfall.toLocaleString()}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <table className="collegebudgeting-loans-table">
                                    <thead>
                                        <tr>
                                            <th>Year</th>
                                            <th>Student Subsidized</th>
                                            <th>Student Unsubsidized</th>
                                            <th>Parent PLUS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {['firstYear', 'secondYear', 'thirdYear', 'fourthYear'].map((year, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {loans[year]?.subsidized !== null
                                                        ? `$${loans[year]?.subsidized.toLocaleString()}`
                                                        : 'N/A'}
                                                </td>
                                                <td>
                                                    {loans[year]?.unsubsidized !== null
                                                        ? `$${loans[year]?.unsubsidized.toLocaleString()}`
                                                        : 'N/A'}
                                                </td>
                                                <td>
                                                    {loans[year]?.parentPlus !== null
                                                        ? `$${loans[year]?.parentPlus.toLocaleString()}`
                                                        : 'N/A'}
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td><strong>Total</strong></td>
                                            <td>
                                                <strong>
                                                    {loanSummary.totalSubsidized !== null
                                                        ? `$${loanSummary.totalSubsidized.toLocaleString()}`
                                                        : 'N/A'}
                                                </strong>
                                            </td>
                                            <td>
                                                <strong>
                                                    {loanSummary.totalUnsubsidized !== null
                                                        ? `$${loanSummary.totalUnsubsidized.toLocaleString()}`
                                                        : 'N/A'}
                                                </strong>
                                            </td>
                                            <td>
                                                <strong>
                                                    {loanSummary.totalParentPlus !== null
                                                        ? `$${loanSummary.totalParentPlus.toLocaleString()}`
                                                        : 'N/A'}
                                                </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    <div className="collegebudgeting-cost-container">
                        <div className="collegebudgeting-header">
                            <h2 className="collegebudgeting-section-header">Student Loan Summary</h2>
                            <button
                                className="toggle-button"
                                onClick={() => toggleSection('studentLoans')}
                            >
                                {expandedSections['studentLoans'] ? '+' : '-'}
                            </button>
                        </div>
                        {!expandedSections['studentLoans'] && (
                            <div className="collegebudgeting-content">
                                <div className="collegebudgeting-cost-category">
                                    <h3 className="collegebudgeting-cost-subheader">Subsidized Student Loans</h3>
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">
                                                Subsidized Loans
                                            </div>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue">
                                            {loanSummary.totalSubsidized !== null
                                                ? `$${loanSummary.totalSubsidized.toLocaleString()}`
                                                : 'N/A'}
                                        </div>
                                    </div>
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">
                                                Interest Rate
                                            </div>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue">
                                            {formatNumberAsPercentage(studentLoanInterestRate,2)}
                                        </div>
                                    </div>
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">
                                                Est. Accrued Interest
                                            </div>
                                            <small>Subsidized loans do not accrue interest while enrolled.</small>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue">
                                            $0
                                        </div>
                                    </div>
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle-total">
                                                Est. Payment
                                            </div>
                                            <small>Payments are due 6 months after graduation.</small>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue-total">
                                            {loanPayments.subsidizedPayment > 0
                                                ? `$${Math.round(loanPayments.subsidizedPayment).toLocaleString()}/mo`
                                                : '$0'}
                                        </div>
                                    </div>
                                </div>
                                <div className="collegebudgeting-cost-category">
                                    <h3 className="collegebudgeting-cost-subheader">Unsubsidized Student Loans</h3>
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">
                                                Unsubsidized Loans
                                            </div>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue">
                                            {loanSummary.totalUnsubsidized !== null
                                                ? `$${loanSummary.totalUnsubsidized.toLocaleString()}`
                                                : 'N/A'}
                                        </div>
                                    </div>
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">
                                                Interest Rate
                                            </div>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue">
                                            {formatNumberAsPercentage(studentLoanInterestRate,2)}
                                        </div>
                                    </div>
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">
                                                Est. Accrued Interest
                                            </div>
                                            <small>Unsubsidized loans accrue interest while enrolled.</small>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue">
                                            {loanSummary.totalUnsubsidizedAccrued !== null
                                                ? `$${Math.round(loanSummary.totalUnsubsidizedAccrued).toLocaleString()}`
                                                : 'N/A'}
                                        </div>
                                    </div>
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle-total">
                                                Est. Payment
                                            </div>
                                            <small>Payments are due 6 months after graduation.</small>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue-total">
                                            {loanPayments.unsubsidizedPayment > 0
                                                ? `$${Math.round(loanPayments.unsubsidizedPayment).toLocaleString()}/mo`
                                                : '$0'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="collegebudgeting-cost-category">
                            <div className="collegebudgeting-cost-subcategory">
                                <div className="collegebudgeting-title-container">
                                    <div className="collegebudgeting-cost-itemtitle-total">
                                        Est. Student Payment
                                    </div>
                                </div>
                                <div className="collegebudgeting-cost-itemvalue-total">
                                    {loanPayments.unsubsidizedPayment > 0 || loanPayments.subsidizedPayment > 0
                                        ? `$${Math.round(loanPayments.subsidizedPayment + loanPayments.unsubsidizedPayment).toLocaleString()}/mo`
                                        : '$0'}
                                </div>
                            </div>
                            <p className="collegebudgeting-cost-hint">Loan payments are based on a standard 10 year repayment plan. Income driven repayment plans may also be available.</p>
                        </div>
                    </div>
                    <div className="collegebudgeting-cost-container">
                        <div className="collegebudgeting-header">
                                <h2 className="collegebudgeting-section-header">Parent Loan Summary</h2>
                                <button
                                    className="toggle-button"
                                    onClick={() => toggleSection('parentLoans')}
                                >
                                    {expandedSections['parentLoans'] ? '+' : '-'}
                                </button>
                            </div>
                            {!expandedSections['parentLoans'] && (
                                <div className="collegebudgeting-content">
                                <div className="collegebudgeting-cost-category">
                                    <h3 className="collegebudgeting-cost-subheader">Parent Loans</h3>
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">
                                                Total Parent PLUS Loans
                                            </div>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue">
                                            {loanSummary.totalParentPlus !== null
                                                ? `$${loanSummary.totalParentPlus.toLocaleString()}`
                                                : 'N/A'}
                                        </div>
                                    </div>
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">
                                                Interest Rate
                                            </div>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue">
                                            {formatNumberAsPercentage(parentPlusLoanInterestRate,2)}
                                        </div>
                                    </div>
                                    <div className="collegebudgeting-cost-subcategory">
                                        <div className="collegebudgeting-title-container">
                                            <div className="collegebudgeting-cost-itemtitle">
                                                Est. Accrued Interest
                                            </div>
                                            <small>Parent PLUS loans accrue interest while enrolled.</small>
                                        </div>
                                        <div className="collegebudgeting-cost-itemvalue">
                                            {loanSummary.totalParentPlusAccrued !== null
                                                ? `$${Math.round(loanSummary.totalParentPlusAccrued).toLocaleString()}`
                                                : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="collegebudgeting-cost-category">
                            <div className="collegebudgeting-cost-subcategory">
                                <div className="collegebudgeting-title-container">
                                    <div className="collegebudgeting-cost-itemtitle-total">
                                        Est. Parent Payment
                                    </div>
                                    <small>Payments are due 6 months after graduation.</small>
                                </div>
                                <div className="collegebudgeting-cost-itemvalue-total">
                                    {loanPayments.parentPlusPayment > 0
                                        ? `$${Math.round(loanPayments.parentPlusPayment).toLocaleString()}/mo`
                                        : '$0'}
                                </div>
                            </div>
                            <p className="collegebudgeting-cost-hint">Loan payments are based on a standard 10 year repayment plan. Income driven repayment plans may also be available.</p>
                        </div>
                    </div>
                    <div className="collegebudgeting-cost-container">
                        <h2 className="collegebudgeting-section-header">Student Loan Affordability</h2>
                        <p className="collegebudgeting-cost-hint">Student loan affordability depends on the student's income after graduating.</p>
                        <p className="collegebudgeting-cost-hint">According to the Consumer Financial Protection Bureau (CFPB), student loan payments that exceed <strong>10%</strong> of pre-tax income might be unaffordable.</p>
                        <h3>Fields of Study</h3>
                        {topFields.length > 0 ? (
                            <table className="fields-of-study-table">
                                <thead>
                                    <tr>
                                        <th>Field of Study</th>
                                        <th>Median Annual Income</th>
                                        <th>Payment-to-Income Ratio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {topFields.map((field, index) => {
                                        const pti = calculatePTI(
                                            field.EARN_MDN_4YR, // Annual Income
                                            loanPayments.subsidizedPayment + loanPayments.unsubsidizedPayment // Total student payment
                                        );

                                        const isPTIRed = pti > 0.1; // PTI > 10%
                                        return (
                                            <tr key={index}>
                                                <td>{field.CIPDESC.replace(/\./g, '')}</td>
                                                <td>${field.EARN_MDN_4YR.toLocaleString()}</td>
                                                <td style={{ color: isPTIRed ? 'red' : 'inherit' }}>
                                                    {isNaN(pti) || pti === null || pti === undefined ? 'N/A' : formatNumberAsPercentage(pti, 1)}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <p>No fields of study data available.</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
