import React, { useState } from 'react';

export default function FinancialInfoModal({ isFinOpen, closeFinModal, initialData }) {
    const US_STATES = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];
    const [studentState, setStudentState] = useState(initialData?.studentState || null);
    const [studentDependent, setStudentDepedent] = useState(initialData?.studentDependent|| null);
    const [savingsContribution, setSavingsContribution] = useState(initialData?.savingsContribution || null);
    const [knowFafsa, setKnowFafsa] = useState(initialData?.knowFafsa || null); // Dropdown for "Do you know your FAFSA?"
    const [estimateFafsa, setEstimateFafsa] = useState(initialData?.estimateFafsa || null); // Dropdown for "Would you like to estimate FAFSA?"
    const [sai, setSai] = useState(initialData?.sai || null); // Student Aid Index
    const [pellGrant, setPellGrant] = useState(initialData?.pellGrant || null); // Estimated Pell Grant

    const apiUrl = process.env.REACT_APP_API_URL || '';

    const isFormValid = (() => {
        if (!studentState) return false;
        if (!studentDependent) return false;
        if (!savingsContribution) return false;
    
        if (knowFafsa === 'no' && estimateFafsa === 'no_federal_aid') {
            // State 1: Savings contribution is set, FAFSA is not considered
            return true;
        }
    
        if (knowFafsa === 'yes' && sai !== '' && pellGrant !== '') {
            // State 2: Savings contribution is set, FAFSA is known, and SAI and Pell Grant are provided
            return true;
        }
    
        if (
            knowFafsa === 'no' &&
            estimateFafsa === 'estimate_fafsa' &&
            sai !== '' &&
            pellGrant !== ''
        ) {
            // State 3: Savings contribution is set, FAFSA estimation is chosen, and SAI and Pell Grant are provided
            return true;
        }
    
        return false; // Default to invalid if none of the states match
    })();
    

    const formatCurrency = (value) => {
        if (value === '' || value === '-' || isNaN(Number(value))) return value; // Return raw input for incomplete states
        const numberValue = Number(value);
        const prefix = numberValue < 0 ? '-' : ''; // Keep the negative sign
        return `${prefix}$${Math.abs(numberValue).toLocaleString()}`; // Format the absolute value
    };      

    const handleInputChange = (e) => {
        const rawValue = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        if (rawValue === '' || (Number(rawValue) >= 0 && Number(rawValue) <= 999999)) {
            setSavingsContribution(rawValue); // Store the raw numeric value
        }
    };

    const handleSaiChange = (e) => {
        // Allow numbers and an optional leading negative sign
        const rawValue = e.target.value.replace(/[^0-9-]/g, ''); // Allow digits and "-"
        
        // Ensure the value is within the valid range or empty
        if (
            rawValue === '' || // Allow empty input
            (rawValue === '-' && sai !== '-') || // Allow a single "-" as input
            (Number(rawValue) >= -1500 && Number(rawValue) <= 999999)
        ) {
            setSai(rawValue); // Store raw value
        }
    };

    const handlePellChange = (e) => {
        const rawValue = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        if (rawValue === '' || (Number(rawValue) >= 0 && Number(rawValue) <= 7395)) {
            setPellGrant(rawValue); // Store the raw numeric value
        }
    };
    

    const handleSubmit = async () => {
        // Consolidate all data to send to the backend
        const studentInfo = {
            studentState,
            studentDependent,
            savingsContribution: parseFloat(savingsContribution),
            knowFafsa,
            estimateFafsa: estimateFafsa, // Include only if "no" selected
            sai: parseFloat(sai), // Include if SAI is provided
            pellGrant: parseFloat(pellGrant), // Include if Pell Grant is provided
        };
    
        try {
            const response = await fetch(`${apiUrl}/student-info`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // Send cookies with the request
                body: JSON.stringify(studentInfo), // Send the data as JSON
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log('Student information saved successfully:', data);
                closeFinModal(); // Close the modal on success
            } else {
                const errorData = await response.json();
                console.error('Failed to save student information:', errorData);
                alert(`Error: ${errorData.error || 'An error occurred while saving the data.'}`);
            }
        } catch (error) {
            console.error('Error saving student information:', error);
            alert('A network or server error occurred. Please try again.');
        }
    };    

    if (!isFinOpen) return null;

    return (
        <div className="financialmodal-overlay">
            <div className="financialmodal-content">
                <h2 className="financialmodal-header">Complete your profile</h2>
                <p className="financialmodal-description">
                    We need some information to estimate your tuition, federal aid and budget.
                </p>
                <form className="financialmodal-form" onSubmit={(e) => e.preventDefault()}>
                    {/* Savings Contribution */}
                    <div className="financialmodal-form-group">
                        <label className="financialmodal-label">What state do you live in?</label>
                        <select
                            className="financialmodal-input financialmodal-select"
                            value={studentState}
                            onChange={(e) => setStudentState(e.target.value)}
                            required
                        >
                            <option value="">Select a state</option>
                            {US_STATES.map((state, index) => (
                                <option className="filterValue" key={index} value={state}>{state}</option>
                            ))}
                        </select>
                        <small className="financialmodal-input-helper">
                            We use this to estimate whether you qualify for in-state tuition at public universities.
                        </small>
                    </div>
                    <div className="financialmodal-form-group">
                        <label className="financialmodal-label">Is the student a dependent or independent?</label>
                        <select
                            className="financialmodal-input financialmodal-select"
                            value={studentDependent}
                            onChange={(e) => setStudentDepedent(e.target.value)}
                            required
                        >
                            <option value="">Select</option>
                            <option value="dependent">Dependent</option>
                            <option value="independent">Indepedent</option>
                        </select>
                        <small className="financialmodal-input-helper">
                            A dependent is someone who relies on another individual for financial support, usually a child or other relative.
                        </small>
                    </div>
                    <div className="financialmodal-form-group">
                        <label className="financialmodal-label">
                            How much of your savings (including 529 funds) do you plan to contribute per year for college expenses?
                        </label>
                        <input
                            className="financialmodal-input"
                            type="text" // Use text to allow formatting
                            value={savingsContribution === null ? "" : formatCurrency(savingsContribution)}
                            onChange={handleInputChange}
                            placeholder="Enter savings contribution"
                        />
                        <small className="financialmodal-input-helper">
                            Enter a value between $0 and $999,999 including any expected family contributions to college expenses. A 529 is a tax-advantaged account used for education expenses.
                        </small>
                    </div>

                    {/* Know FAFSA Dropdown */}
                    <div className="financialmodal-form-group">
                        <label className="financialmodal-label">Have you submitted your FAFSA form?</label>
                        <select
                            className="financialmodal-input financialmodal-select"
                            value={knowFafsa}
                            onChange={(e) => {
                                setKnowFafsa(e.target.value);
                                setEstimateFafsa('know_fafsa');
                                setSai(null); //reset
                                setPellGrant(null); //reset
                            }}
                            required
                        >
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    {/* SAI and Pell Grant Fields (Shown if Yes) */}
                    {knowFafsa === 'yes' && (
                        <>
                            <p className="financialmodal-fafsadescription">
                                Please enter your Student Aid Index (SAI) and maximum Pell Grant. You can find this information on your{' '}
                                <a 
                                    href="https://studentaid.gov/articles/things-after-fafsa/" 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className="financialmodal-link"
                                >
                                    FAFSA confirmation page
                                </a>.
                            </p>
                            <div className="financialmodal-form-group">
                                <label className="financialmodal-label">Student Aid Index (SAI)</label>
                                <input
                                    className="financialmodal-input"
                                    type="text"
                                    value={sai === null ? "" : formatCurrency(sai)}
                                    onChange={handleSaiChange}
                                    placeholder="Enter your SAI"
                                />
                                <small className="financialmodal-input-helper">
                                    Enter a value between -$1,500 and $999,999.
                                </small>
                            </div>
                            <div className="financialmodal-form-group">
                                <label className="financialmodal-label">Maximum Pell Grant</label>
                                <input
                                    className="financialmodal-input"
                                    type="text"
                                    value={pellGrant === null ? "" : formatCurrency(pellGrant)}
                                    onChange={handlePellChange}
                                    placeholder="Enter your maximum Pell Grant"
                                />
                                <small className="financialmodal-input-helper">
                                    Enter a value between $0 and $7,395.
                                </small>
                            </div>
                        </>
                    )}

                    {/* Estimate FAFSA Dropdown (Shown if No) */}
                    {knowFafsa === 'no' && (
                        <div className="financialmodal-form-group">
                            <label className="financialmodal-label">Are you considering using federal aid, such as grants or loans, to help pay for college?</label>
                            <select
                                className="financialmodal-input financialmodal-select"
                                value={estimateFafsa}
                                onChange={(e) => setEstimateFafsa(e.target.value)}
                                required
                            >
                                <option value="">Select an option</option>
                                <option value="estimate_fafsa">Yes</option>
                                <option value="no_federal_aid">No, I do not plan on using federal aid to help pay for college.</option>
                            </select>
                        </div>
                    )}
                    {estimateFafsa === 'estimate_fafsa' && (
                        <>
                            <div>Please take a few minutes to&nbsp;
                                <a 
                                        href="https://studentaid.gov/aid-estimator/" 
                                        target="_blank" 
                                        rel="noopener noreferrer" 
                                        className="financialmodal-link"
                                    >
                                         complete the official FAFSA aid estimator.
                                    </a> Once complete, please enter your estimated Student Aid Index (SAI) and Pell Grant below.</div>
                            <div className="financialmodal-form-group">
                                <label className="financialmodal-label">Student Aid Index (SAI)</label>
                                <input
                                    className="financialmodal-input"
                                    type="text"
                                    value={sai === null ? "" : formatCurrency(sai)}
                                    onChange={handleSaiChange}
                                    placeholder="Enter your SAI"
                                />
                                <small className="financialmodal-input-helper">
                                    Enter a value between -$1,500 and $999,999.
                                </small>
                            </div>
                            <div className="financialmodal-form-group">
                                <label className="financialmodal-label">Maximum Pell Grant</label>
                                <input
                                    className="financialmodal-input"
                                    type="text"
                                    value={pellGrant === null ? "" : formatCurrency(pellGrant)}
                                    onChange={handlePellChange}
                                    placeholder="Enter your maximum Pell Grant"
                                />
                                <small className="financialmodal-input-helper">
                                    Enter a value between $0 and $7,395.
                                </small>
                            </div>
                        </>
                    )}

                    {/* Actions */}
                    <div className="financialmodal-actions">
                        <button
                            type="button"
                            className="financialmodal-button financialmodal-cancel"
                            onClick={closeFinModal}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="financialmodal-button"
                            onClick={handleSubmit}
                            disabled={!isFormValid}
                        >
                            Confirm
                        </button>
                    </div>
                    <small>By proceeding, I acknowledge and agree that CollegeNPV displays estimates of costs, federal aid and outcomes for informational purposes to assist with college planning. This information can change over time and might not apply to you. Actual costs and aid to be provided by universities at their discretion. CollegeNPV is not affiliated with and does not endorse any university. We recommend that you confirm important info with the school, like costs and requirements.</small>
                </form>
            </div>
        </div>
    );
}
