import React, { useState, useContext } from 'react';
import AccountCreationModal from '../AccountCreationModal';
import { UserContext } from '../UserContext';
import AdvisorDashboard from '../AdvisorDashboard';
import CollegeListBuilder from './CollegeListBuilder';
import TestimonialsSection from '../AdvisorTestimonials';
import ProductDescriptionSection from '../AdvisorProduct';
import useDocumentTitle from '../useDocumentTitle';

export default function CollegeAdvisor() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalStep, setModalStep] = useState(1);
    const { userInfo } = useContext(UserContext); // Access user context
    const apiUrl = process.env.REACT_APP_API_URL || '';

    useDocumentTitle('College Advisor');

    const openModal = (step = 1) => {
        setModalStep(step);
        setIsModalOpen(true);
    };
    const closeModal = () => setIsModalOpen(false);

    // Render content based on user's state
    const renderContent = () => {
        if (!userInfo) {
            // State 1: Not logged in
            return (
                <div className="collegeadvisor-welcome">
                    <div className="collegeadvisor-headercontainer">
                        <h1>College planning for your budget</h1>
                        <img src={`${apiUrl}/collegeimages//cnpv_favicon.png`} alt="Brand Logo" className="collegeadvisor-brandmark"/>
                    </div>
                    <div className="collegeadvisor-card">
                        <h2 className="collegeadvisor-header">Personalized college financial planning</h2>
                        <p className="collegeadvisor-description">
                            Make informed decisions and take control of your financial future.
                        </p>
                        <ol className="collegeadvisor-steps-list">
                            <li className="collegeadvisor-step">
                            ✅ Build your list of reach, target and safety schools
                            </li>
                            <li className="collegeadvisor-step">
                            ✅ Estimate financial aid and discover schools that fit your budget
                            </li>
                            <li className="collegeadvisor-step">
                            ✅ Compare schools with personalized budgets and ROI estimates
                            </li>
                        </ol>
                        <p className="collegeadvisor-description">
                            Join our community of over <strong>34,000</strong> students and parents.
                        </p>
                        <button className="collegeadvisor-button" onClick={() => openModal(1)}>
                            Get Started
                        </button>
                        <div className="register-login-reroute">Already have an account? <a className="register-login-reroute-link" onClick={() => openModal(2)}><strong>Log In</strong></a></div>
                    </div>
                    <ProductDescriptionSection
                        getStarted={() => openModal(1)}
                    />
                    <TestimonialsSection 
                        getStarted={() => openModal(1)}
                    />
                    
                </div>
            );
        } else if (userInfo.tier === 'free') {
            // State 2: Logged in - Free tier
            return (
                <div className="collegeadvisor-free-container">
                    <div className="collegeadvisor-card">
                        <h2 className="collegeadvisor-header">Welcome Back!</h2>
                        <p className="collegeadvisor-description">
                            You’re currently on the free plan. Upgrade to premium to unlock personalized college budgeting features:
                        </p>
                        <ul className="collegeadvisor-benefits-list">
                            <li>Estimate cost of attendance for each school on your list</li>
                            <li>Build a personalized financial profile and estimate financial aid</li>
                            <li>Forecast loan amounts and student loan payment affordability after graduation</li>
                        </ul>
                        <button className="collegeadvisor-button" onClick={() => openModal(3)}>
                            Upgrade to Premium
                        </button>
                    </div>
                    <CollegeListBuilder 
                            tier={userInfo?.tier}
                            onUpgradeClick={() => openModal(3)}
                    />
                </div>
            );
        } else if (userInfo?.tier === 'advisor') {
            return (
                <div className="collegeadvisor-admissions-profile">
                    <AdvisorDashboard 
                        tier={userInfo?.tier}
                        onUpgradeClick={() => openModal(3)}
                    />
                </div>
            );
        }
    };

    return (
        <div className="collegeadvisor-container">
            {renderContent()}
            <AccountCreationModal
                isOpen={isModalOpen}
                closeModal={closeModal}
                currentStep={modalStep} // Pass the current step
            />
        </div>
    );
}
