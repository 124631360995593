import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import debounce from 'lodash.debounce';

const SquareGridMap = ({
    pcipLookup
}) => {
  const svgRef = useRef();
  const apiUrl = process.env.REACT_APP_API_URL || '';

  const [stateData, setStateData] = useState([]);
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  const stateCoordinates = [
    { id: "AL", name: "Alabama", x: 7, y: 5 },
    { id: "AK", name: "Alaska", x: 0, y: 0 },
    { id: "AZ", name: "Arizona", x: 2, y: 4 },
    { id: "AR", name: "Arkansas", x: 5, y: 4 },
    { id: "CA", name: "California", x: 1, y: 3 },
    { id: "CO", name: "Colorado", x: 3, y: 3 },
    { id: "CT", name: "Connecticut", x: 10, y: 2 },
    { id: "DE", name: "Delaware", x: 9, y: 3 },
    { id: "FL", name: "Florida", x: 9, y: 6 },
    { id: "GA", name: "Georgia", x: 8, y: 5 },
    { id: "HI", name: "Hawaii", x: 0, y: 6 },
    { id: "ID", name: "Idaho", x: 2, y: 1 },
    { id: "IL", name: "Illinois", x: 6, y: 3 },
    { id: "IN", name: "Indiana", x: 6, y: 2 },
    { id: "IA", name: "Iowa", x: 5, y: 2 },
    { id: "KS", name: "Kansas", x: 4, y: 4 },
    { id: "KY", name: "Kentucky", x: 7, y: 3 },
    { id: "LA", name: "Louisiana", x: 5, y: 5 },
    { id: "ME", name: "Maine", x: 11, y: 0 },
    { id: "MD", name: "Maryland", x: 8, y: 3 },
    { id: "MA", name: "Massachusetts", x: 11, y: 1 },
    { id: "MI", name: "Michigan", x: 7, y: 1 },
    { id: "MN", name: "Minnesota", x: 5, y: 1 },
    { id: "MS", name: "Mississippi", x: 6, y: 5 },
    { id: "MO", name: "Missouri", x: 5, y: 3 },
    { id: "MT", name: "Montana", x: 3, y: 1 },
    { id: "NE", name: "Nebraska", x: 4, y: 3 },
    { id: "NV", name: "Nevada", x: 2, y: 2 },
    { id: "NH", name: "New Hampshire", x: 10, y: 1 },
    { id: "NJ", name: "New Jersey", x: 10, y: 3 },
    { id: "NM", name: "New Mexico", x: 3, y: 4 },
    { id: "NY", name: "New York", x: 9, y: 2 },
    { id: "NC", name: "North Carolina", x: 9, y: 4 },
    { id: "ND", name: "North Dakota", x: 4, y: 1 },
    { id: "OH", name: "Ohio", x: 7, y: 2 },
    { id: "OK", name: "Oklahoma", x: 4, y: 5 },
    { id: "OR", name: "Oregon", x: 1, y: 2 },
    { id: "PA", name: "Pennsylvania", x: 8, y: 2 },
    { id: "RI", name: "Rhode Island", x: 11, y: 2 },
    { id: "SC", name: "South Carolina", x: 9, y: 5 },
    { id: "SD", name: "South Dakota", x: 4, y: 2 },
    { id: "TN", name: "Tennessee", x: 6, y: 4 },
    { id: "TX", name: "Texas", x: 4, y: 6 },
    { id: "UT", name: "Utah", x: 2, y: 3 },
    { id: "VT", name: "Vermont", x: 9, y: 1 },
    { id: "VA", name: "Virginia", x: 8, y: 4 },
    { id: "WA", name: "Washington", x: 1, y: 1 },
    { id: "WV", name: "West Virginia", x: 7, y: 4 },
    { id: "WI", name: "Wisconsin", x: 6, y: 1 },
    { id: "WY", name: "Wyoming", x: 3, y: 2 },
    { id: "DC", name: "District of Columbia", x: 11, y: 5 }
  ];

  useEffect(() => {
    // Fetch data from backend
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/highest-net-npv`);
        const data = await response.json();

        // Filter out specific state abbreviations
        const filteredData = data.filter(state => !['PR', 'GU', 'VI', 'MP', 'FM', 'MH', 'AS', 'PW'].includes(state.STABBR));

        // Enrich the fetched data with state coordinates
        const enrichedStateData = filteredData.map(state => {
          const stateCoord = stateCoordinates.find(coord => coord.id === state.STABBR);
          return {
            ...state,
            x: stateCoord?.x,
            y: stateCoord?.y
          };
        });

        setStateData(enrichedStateData);
      } catch (error) {
        console.error('Error fetching state data:', error);
      }
    };

    fetchData();
  }, [apiUrl, pcipLookup]);

  useEffect(() => {
    const handleResize = debounce(() => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    }, 200);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (stateData.length === 0) return;
    drawSvg();
  }, [stateData, dimensions]);

  const drawSvg = () => {
    const gridSize = (dimensions.width - 40) / 12;
    const svgWidth = dimensions.width;
    const legendHeight = 200;
    const svgHeight = gridSize*7 + legendHeight + 20;
    
    
    // Create a color scale based on distinct PCIP codes
    const pcipCodes = [...new Set(stateData.map(d => d.PCIP))];
    //const colorScale = d3.scaleOrdinal().domain(pcipCodes).range(d3.schemeCategory10);

    const colorScale = d3.scaleOrdinal().domain(pcipCodes).range(d3.schemeSet3);

    // Clear previous SVG if it exists
    d3.select(svgRef.current).select('svg').remove();

    // Create the SVG element
    const svg = d3
      .select(svgRef.current)
      .append('svg')
      .attr('width', svgWidth)
      .attr('height', svgHeight)
      .style('overflow-x', 'auto'); // Enable horizontal scrolling for narrower screens

    // Draw squares for each state
    svg
      .selectAll('.state-square')
      .data(stateData)
      .enter()
      .append('rect')
      .attr('class', 'state-square')
      .attr('x', d => d.x * gridSize)
      .attr('y', d => d.y * gridSize)
      .attr('width', gridSize)
      .attr('height', gridSize)
      .attr('fill', d => colorScale(d.PCIP))
      .attr('stroke', '#333')
      .attr('stroke-width', '2')
      .on('mouseover', function (event, d) {
        d3.select(this).attr('fill', '#ff6347'); // Change color on hover
      })
      .on('mouseout', function (event, d) {
        d3.select(this).attr('fill', colorScale(d.PCIP)); // Revert color on mouse out
      })
      .append('title')
      .text(d => `State: ${d.STABBR}\nUniversity: ${d.INSTNM}\nProgram: ${d.CIPDESC}\nNET_NPV: $${d.NET_NPV}`); // Tooltip to display state information

    // Add text labels for each square
    svg
    .selectAll('.state-label')
    .data(stateData)
    .enter()
    .append('text')
    .attr('class', 'state-label')
    .attr('x', d => d.x * gridSize + 5) // Position near the top-left corner of the square
    .attr('y', d => d.y * gridSize + 15) // Adjust y to position label inside the top-left corner
    .attr('text-anchor', 'start')
    .attr('font-size', `${gridSize * 0.15}px`)
    .attr('fill', '#333')
    .text(d => d.STABBR);

  // Add NET_NPV value to the center of each square
  svg
    .selectAll('.net-npv-label')
    .data(stateData)
    .enter()
    .append('text')
    .attr('class', 'net-npv-label')
    .attr('x', d => d.x * gridSize + gridSize / 2) // Center horizontally in the square
    .attr('y', d => d.y * gridSize + gridSize / 2 + 5) // Center vertically in the square
    .attr('text-anchor', 'middle')
    .attr('font-size', `${gridSize * 0.22}px`)
    .attr('fill', '#000')
    .text(d => {
        const value = '$' + (d.NET_NPV / 1000).toFixed(0) + 'k';
        return d.NET_NPV >= 1000000 ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : value;
      });

        // Add legend
        const legend = svg.append('g').attr('class', 'legend').attr('transform', `translate(20, ${svgHeight - legendHeight})`);

        // Wrap the legend vertically
        pcipCodes.forEach((pcip, index) => {
        const legendItem = legend.append('g').attr('transform', `translate(0, ${index * 30})`);

        // Add color box
        legendItem
            .append('rect')
            .attr('width', 20)
            .attr('height', 20)
            .attr('fill', colorScale(pcip))
            .attr('stroke', '#333');

        // Add label
        const fieldLabel = pcipLookup.find(lookup => lookup.CODE === pcip)?.Field || `PCIP: ${pcip}`;
        legendItem
            .append('text')
            .attr('x', 30)
            .attr('y', 15)
            .attr('font-size', '14px')
            .attr('fill', '#000')
            .text(fieldLabel);
        });
    };



  return <div ref={svgRef}></div>;
};

export default SquareGridMap;