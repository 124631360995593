import React, { useState } from 'react';
import CheckoutForm from './AdvisorUpgradeStripe';

export default function UpgradeStep({ closeModal }) {
    const [isPaymentInitiated, setPaymentInitiated] = useState(false);
    
    /* global gtag */
    const gtagSendEvent = (callback) => {
        const eventCallback = () => {
            if (typeof callback === 'function') {
                callback();
            }
        };
        // Trigger the event
        gtag('event', 'ads_conversion_Sign_Up_1', {
            'event_callback': eventCallback,
            'event_timeout': 2000,
            // Add other event parameters if needed
        });
    };

    const handleUpgrade = async () => {
        // Send gtag event before proceeding with the upgrade
        gtagSendEvent(() => {
            setPaymentInitiated(true); // Trigger the payment flow
        });
    };

    return (
        <>
            {!isPaymentInitiated ? (
                <>
                    <h2 className="collegeadvisor-modal-price">Upgrade to Premium</h2>
                    <h1 className="collegeadvisor-modal-price">$19</h1>
                    <p className="collegeadvisor-modal-pricedesc">6 months of access</p>
                    <div className="collegeadvisor-modal-desc">Confident budget planning for each school on your list. Estimate your costs, financial need, scholarships, loans and outcomes.</div>
                    <div className="collegeadvisor-upgrade-container">
                        <div className="collegeadvisor-upgrade-option">
                            <h3>Premium Features</h3>
                            <ul className="collegeadvisor-upgrade-features">
                                <li>✔ Personalized college financial profile</li>
                                <li>✔ Estimate costs of attendance</li>
                                <li>✔ Estimate scholarships and grants</li>
                                <li>✔ Estimate eligible financial aid</li>
                                <li>✔ Plan family contributions to costs</li>
                                <li>✔ Forecast student and parent loans</li>
                                <li>✔ Evaluate student loan affordability after graduation</li>
                            </ul>
                        </div>
                    </div>
                    <div className="collegeadvisor-upgrade-actions">
                        <button
                            className="collegeadvisor-button"
                            onClick={handleUpgrade}
                        >
                            Upgrade
                        </button>
                    </div>
                </>
            ) : (
                <CheckoutForm /> // Display the payment UI
            )}
        </>
    );
}