import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function CLBSchoolList ({
    index,
    INSTNM,
    UNITID,
    NET_NPV,
    ADM_RATE,
    ACTCM25,
    ACTCM75,
    SATCM25,
    SATCM75,
    handleRemove,
    formatCurrency,
    formatNumberAsPercentage,
    navigateAndScrollToTop,
    onUpgradeClick,
    tier,
    familyInfo,
    openFinancialModal
}
) {

    const apiUrl = process.env.REACT_APP_API_URL || '';

    const fontColor = NET_NPV === null ? '#ccc' : (NET_NPV >= 0 ? '#05BFDD' : '#F92654');

    const handleButtonClick = () => {
        if (tier === 'free') {
            onUpgradeClick(); // Trigger the upgrade modal
        } else if (tier === 'advisor' && !familyInfo) {
            openFinancialModal();
        } else if (tier === 'advisor') {
            navigateAndScrollToTop(`/collegeadvisor/budgeting?INSTNM=${encodeURIComponent(INSTNM)}&UNITID=${encodeURIComponent(UNITID)}`); // Navigate to the budgeting page
        }
    };
 
    return (
        <div>
            <li key={index} className="clb-school-item">
                <div className="clb-school-item-section1">
                    <div className="clb-school-item-header">
                        <div className="clb-school-name">
                            <a 
                                href={`/collegepage?INSTNM=${encodeURIComponent(INSTNM)}&UNITID=${encodeURIComponent(UNITID)}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigateAndScrollToTop(`/collegepage?INSTNM=${encodeURIComponent(INSTNM)}&UNITID=${encodeURIComponent(UNITID)}`);
                                }}
                                aria-label={`View more information about ${INSTNM}`}
                            >
                                <h3 className="clb-school-name-h3">{INSTNM}</h3>
                            </a>
                        </div>
                        <div className="clb-remove-button-container">
                            <button className="clb-remove-button" onClick={() => handleRemove(UNITID, INSTNM)}>&times;</button>
                        </div>
                    </div>
                    <div className="clb-fos-valuation">
                        <div className="clb-fos-valuation-section">
                            <span className="clb-fos-valuation-type">Return On Investment:</span>
                            <span className="clb-fos-valuation-value"
                            style={{ color: fontColor }}
                            >
                                {NET_NPV !== null ? formatCurrency(NET_NPV) : "N/A"} 
                            </span>
                            <div className="calculation2-header">
                                <img src={`${apiUrl}/collegeimages//cnpv_favicon.png`} alt="Brand Logo" className="clb-fos-brandmark" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clb-school-item-section2">
                    <div className="item2">
                        <span className="label2">Acceptance:</span>
                        <span className="value2">{formatNumberAsPercentage(ADM_RATE)}</span>
                    </div>
                    <div className="item2">
                        <span className="label2">ACT Range:</span>
                        <span className="value2">{ACTCM25}-{ACTCM75}</span>
                    </div>
                    <div className="item2">
                        <span className="label2">SAT Range:</span>
                        <span className="value2">{SATCM25}-{SATCM75}</span>
                    </div>
                </div>
                <div className="clb-school-actions">
                    <button
                        className="collegeadvisor-button"
                        onClick={handleButtonClick} // Use the new handler
                    >
                        Calculate Budget
                    </button>
                </div>
            </li>
        </div>
    );
}