import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CollegeRank from "../collegerankv3";
import SearchComponent from "../SearchComponent";
import Pagination from "../pagination";
import { Navigate } from "react-router-dom";
import FilterModal from "../FilterModal";
import useDocumentTitle from "../useDocumentTitle";
import AdResponsiveHorizontal from "../AdResponsiveHorizontal";
import SearchToggle from "../RankingsSearchToggle";

export default function CollegeRankings() {
    const { page } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    useDocumentTitle('Browse Colleges Ranked by Return on Investment');
    
    //result states
    const [colleges,setColleges] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    //UI states
    const [showFilterModal, setShowFilterModal] = useState(false);
    
    //filter states
    const initialPage = parseInt(query.get('page'), 10) || 1;
    const initialSearchQuery = decodeURIComponent(query.get('query') || '');
    const initialSelectedState = query.get('state') || '';

    //filter states to pass to modal
    const initialState = query.get('state') || ''; // Default to empty string if not provided
    const initialTestScoreType = query.get('testScoreType') || 'None'; // Default to 'None'
    const initialTargetScore = query.get('targetScore') || 20; // Default to 20
    const initialSort = query.get('sort') || 'rank_desc'; // Default to 20

    const [currentPage, setCurrentPage] = useState(initialPage);
    const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
    const [selectedState, setSelectedState] = useState(initialSelectedState);
    const [testScoreType, setTestScoreType] = useState('');
    const [targetScore, setTargetScore] = useState('');
    const [sortOption, setSortOption] = useState(initialSort);


    const apiUrl = process.env.REACT_APP_API_URL || '';

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const pageNum = parseInt(query.get('page') || page, 10);
        const searchQueryFromURL = decodeURIComponent(query.get('query') || '');
        const selectedStateFromURL = query.get('state') || '';
        const testScoreTypeFromURL = query.get('testScoreType') || '';
        const targetScoreFromURL = query.get('targetScore') || '';
        const sortFromURL = query.get('sort') || '';
        
        setCurrentPage(pageNum);
        setSearchQuery(searchQueryFromURL);
        setSelectedState(selectedStateFromURL);
        setTestScoreType(testScoreTypeFromURL);
        setTargetScore(targetScoreFromURL);
        setSortOption(sortFromURL);
    
        const fetchUrl = `${apiUrl}/college?page=${pageNum}&query=${encodeURIComponent(searchQueryFromURL)}&state=${encodeURIComponent(selectedStateFromURL)}&testScoreType=${encodeURIComponent(testScoreTypeFromURL)}&targetScore=${encodeURIComponent(targetScoreFromURL)}&sort=${sortOption}`;

        fetch(fetchUrl)
        .then(response => response.json())
        .then(collegeres => {
            setColleges(collegeres.results);
            setTotalPages(collegeres.totalPages); 
            setTotalResults(collegeres.total);
            //console.log(colleges);
        })
        .catch(error => {
            console.error("Error fetching data:", error);
        });
        //console.log(`URL Parameters: Page = ${pageNum}, Query = ${searchQueryFromURL}, State = ${selectedStateFromURL}`);
    }, [location.search]);
    
    const updateURL = (newPage, newSearchQuery = searchQuery, newState = selectedState, newTestScoreType = testScoreType, newTargetScore = targetScore, newSortOption = sortOption) => {
        const searchParams = new URLSearchParams();
        
        if (newSearchQuery) searchParams.set("query", encodeURIComponent(decodeURIComponent(newSearchQuery)));
        if (newState) searchParams.set("state", newState);
        if (newTestScoreType) searchParams.set("testScoreType", newTestScoreType);
        if (newTargetScore) searchParams.set("targetScore", newTargetScore);
        searchParams.set("page", newPage.toString());
        searchParams.set("sort", newSortOption); // Include sort option in URL
        
        navigate(`/collegerankings/?${searchParams.toString()}`, { replace: true });
    };
    
    

    const handleSearchResults = (searchResults) => {
        setColleges(searchResults.results);
        setTotalPages(searchResults.totalPages); 
        setTotalResults(searchResults.total);

        const newPage = 1;
        updateURL(newPage, searchQuery, selectedState);
        //console.log('Updating URL with search results!');
      };

      const handlePageChange = (newPage) => {
        // Construct the search query including test score type and target score
        const searchParams = new URLSearchParams({
            page: newPage,
            query: encodeURIComponent(decodeURIComponent(searchQuery)),
            state: encodeURIComponent(selectedState),
            sort: encodeURIComponent(sortOption)
        });
    
        // Only add testScoreType and targetScore to the search parameters if they are set
        if (testScoreType && testScoreType !== 'None') {
            searchParams.set('testScoreType', testScoreType);
        }
        if (targetScore) {
            searchParams.set('targetScore', targetScore);
        }
    
        // Use navigate to update the URL with the new search parameters
        navigate({
            pathname: '/collegerankings',
            search: `?${searchParams.toString()}`
        });
    
        window.scrollTo(0, 0);
        console.log('Updating page with filters:', searchParams.toString());
    };

    const updateURLWithSearch = (query, state) => {
        // Assuming page resets to 1 on new search
        updateURL(1, query, state); // Re-use your existing updateURL logic
        console.log('Updating URL with search!');
    };

    const handleFilterModal = () => {
        setShowFilterModal(true);
    }

    // In CollegeRankings component
    const handleFilterApply = (newSelectedState, newTestScoreType, newTargetScore) => {
        setSelectedState(newSelectedState);
        setTestScoreType(newTestScoreType);
        setTargetScore(newTargetScore);
    
        const newPage = 1;
        updateURL(newPage, searchQuery, newSelectedState, newTestScoreType, newTargetScore);

            // Construct the fetch URL with all necessary parameters, including test score type and target score
        let fetchUrl = `${apiUrl}/college?page=${newPage}&query=${encodeURIComponent(searchQuery)}&state=${encodeURIComponent(newSelectedState)}`;
    
        // Conditionally add test score type and target score to the URL, if applicable
        if (newTestScoreType && newTestScoreType !== 'None' && newTargetScore) {
            fetchUrl += `&testScoreType=${encodeURIComponent(newTestScoreType)}&targetScore=${encodeURIComponent(newTargetScore)}`;
        }

        fetchUrl += `&sort=${sortOption}`
        
        fetch(fetchUrl)
        .then(response => response.json())
        .then(collegeres => {
            setColleges(collegeres.results);
            setTotalPages(collegeres.totalPages); 
            setTotalResults(collegeres.total);
        })
        .catch(error => {
            console.error("Error fetching data with state filter:", error);
        });
    
        // Close the filter modal
        setShowFilterModal(false);
    };

    const handleSortChange = (event) => {
        const newPage = 1;
        const selectedSortOption = event.target.value;
        setSortOption(selectedSortOption);
        updateURL(newPage, searchQuery, selectedState, testScoreType, targetScore, selectedSortOption);
    };
    

    function formatNumberWithCommas(number) {
        return number ? number.toLocaleString() : "";
    };

    const SORT_OPTIONS = [
        { label: 'Rank: High', value: 'rank_desc' },
        { label: 'Rank: Low', value: 'rank_asc' },
        { label: 'Acceptance: High', value: 'acceptance_rate_desc' },
        { label: 'Acceptance: Low', value: 'acceptance_rate_asc' },
    ];
      
    
    return (
        <div>
            <h1 className="collegerankings-headline">Search colleges ranked by return on investment</h1>
            <SearchToggle />
            <h2 className="search-results">{formatNumberWithCommas(totalResults)} colleges</h2>
            <SearchComponent
                onSearchResults={handleSearchResults}
                selectedState={selectedState}
                testScoreType={testScoreType}
                targetScore={targetScore}
                sortOption={sortOption}
                onUpdateURL={updateURLWithSearch}
            />
            <div className="filter-sort-container">
                <button className="filter-button" onClick={handleFilterModal}>
                    Filter
                    {(initialState !== '' || initialTestScoreType !== 'None') && <span className="filter-active-indicator"></span>}
                </button>
                <select onChange={handleSortChange} value={sortOption} className="sort-dropdown" aria-label="Sort Dropdown">
                    {SORT_OPTIONS.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
            <FilterModal 
                show={showFilterModal} 
                onClose={() => setShowFilterModal(false)} 
                onApplyFilters={handleFilterApply}
                initialState={initialState}
                initialTestScoreType={initialTestScoreType}
                initialTargetScore={initialTargetScore}
            />
            <div className="college-rankings-container">
                {colleges.length > 0 && colleges.map((college) => (
                    <CollegeRank key={college._id} {...college} />
                ))}
            </div>
            <Pagination page={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            <AdResponsiveHorizontal />
        </div>
    );
}