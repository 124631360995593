import { createContext, useState } from "react";

export const UserContext = createContext({});

export function UserContextProvider({ children }) {
    const apiUrl = process.env.REACT_APP_API_URL || '';
    
    const [userInfo, setUserInfo] = useState({});

    // Function to fetch and update user information
    const refreshUserInfo = async () => {
        try {
            const response = await fetch(`${apiUrl}/profile`, {
                credentials: 'include', // Ensure cookies are sent
            });

            if (response.ok) {
                const user = await response.json();
                setUserInfo(user); // Update the context with the latest user data
            } else {
                //console.error('Failed to fetch user profile:', response.statusText);
                setUserInfo(null); // Reset user info if not authenticated
            }
        } catch (error) {
            console.error('Error refreshing user info:', error);
            setUserInfo(null); // Reset user info on error
        }
    };

    return (
        <UserContext.Provider value={{ userInfo, setUserInfo, refreshUserInfo }}>
            {children}
        </UserContext.Provider>
    );
}
