import React, { useEffect, useState } from 'react';
import AdResponsiveHorizontal from '../AdResponsiveHorizontal';
import useDocumentTitle from '../useDocumentTitle';

const apiUrl = process.env.REACT_APP_API_URL || '';

export default function CollegeDashboard() {
    useDocumentTitle('College ROI Dashboard');
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState({}); // Track expanded rows
    const [sortConfig, setSortConfig] = useState({ key: 'code_netnpv_count', direction: 'desc' });

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch all required datasets
                const [codesummaryRes, codeunemploymentRes, collegemajorinfoRes] = await Promise.all([
                    fetch(`${apiUrl}/codesummary`),
                    fetch(`${apiUrl}/codeunemployment`),
                    fetch(`${apiUrl}/filtered-collegemajorinfo`)
                ]);

                if (!codesummaryRes.ok || !codeunemploymentRes.ok || !collegemajorinfoRes.ok) {
                    throw new Error(`HTTP error! status: ${codesummaryRes.status}, ${codeunemploymentRes.status}, ${collegemajorinfoRes.status}`);
                }

                const [codesummaryData, codeunemploymentData, collegemajorinfoData] = await Promise.all([
                    codesummaryRes.json(),
                    codeunemploymentRes.json(),
                    collegemajorinfoRes.json()
                ]);

                // Create maps for merging
                const unemploymentMap = new Map(
                    codeunemploymentData.map(item => [Number(item.CIP), item]) // Map CIP to unemployment data
                );

                const riskLevelMap = new Map(
                    collegemajorinfoData.map(item => [Number(item.cip_code), item.automation_risk?.risk_level]) // Map cip_code to risk_level
                );

                // Transform and merge data
                const transformedData = codesummaryData
                    .map(record => {
                        // First, merge unemployment and risk data into cipcodes
                        const mergedCipcodes = record.cipcodes
                            .map(cip => ({
                                ...cip,
                                ...unemploymentMap.get(Number(cip.CIPCODE)), // Merge unemployment data
                                risk_level: riskLevelMap.get(Number(cip.CIPCODE)) || "N/A", // Merge risk level
                                risk_level_numeric:
                                riskLevelMap.get(Number(cip.CIPCODE)) === "Low"
                                    ? 1
                                    : riskLevelMap.get(Number(cip.CIPCODE)) === "Moderate"
                                    ? 2
                                    : riskLevelMap.get(Number(cip.CIPCODE)) === "High"
                                    ? 3
                                    : null, // Map risk levels to numeric values
                                positiveROI: cip.cip_netnpv_count > 0
                                    ? (cip.cip_posnpv_count / cip.cip_netnpv_count)
                                    : "N/A",
                                cip_median_netnpv: cip.cip_netnpv_count > 0
                                    ? cip.cip_median_netnpv
                                    : "N/A"
                            }))
                            .sort((a, b) => b.cip_netnpv_count - a.cip_netnpv_count);

                        // Calculate weighted averages for UNEMPLOYMENT_RATE and UNDEREMPLOYMENT_RATE
                        const totalWeight = mergedCipcodes.reduce((sum, cip) => sum + (cip.cip_netnpv_count || 0), 0);

                        const hasValidUnemploymentRates = mergedCipcodes.some(cip => cip.UNEMPLOYMENT_RATE !== null && cip.UNEMPLOYMENT_RATE !== undefined);
                        const hasValidUnderemploymentRates = mergedCipcodes.some(cip => cip.UNDEREMPLOYMENT_RATE !== null && cip.UNDEREMPLOYMENT_RATE !== undefined);

                        const weightedUnemploymentRate =
                            totalWeight > 0 && hasValidUnemploymentRates
                                ? mergedCipcodes.reduce(
                                      (sum, cip) => sum + ((cip.UNEMPLOYMENT_RATE || 0) * (cip.cip_netnpv_count || 0)),
                                      0
                                  ) / totalWeight
                                : null;

                        const weightedUnderemploymentRate =
                            totalWeight > 0 && hasValidUnderemploymentRates
                                ? mergedCipcodes.reduce(
                                      (sum, cip) => sum + ((cip.UNDEREMPLOYMENT_RATE || 0) * (cip.cip_netnpv_count || 0)),
                                      0
                                  ) / totalWeight
                                : null;
                            
                        // Calculate weighted average risk level
                        const validCipcodes = mergedCipcodes.filter(cip => cip.risk_level_numeric !== null);

                        // Calculate total weight with valid risk levels
                        const validTotalWeight = validCipcodes.reduce(
                            (sum, cip) => sum + (cip.cip_netnpv_count || 0),
                            0
                        );

                        // Check if all risk levels are "N/A"
                        const allRiskLevelsNA = validCipcodes.length === 0;

                        // Calculate weighted average risk level
                        const weightedRiskLevel =
                            !allRiskLevelsNA && validTotalWeight > 0
                                ? validCipcodes.reduce(
                                    (sum, cip) =>
                                        sum + ((cip.risk_level_numeric || 0) * (cip.cip_netnpv_count || 0)),
                                    0
                                ) / validTotalWeight
                                : null;

                        // Map weighted average to risk category
                        const overallRisk =
                            allRiskLevelsNA || weightedRiskLevel === null
                                ? "N/A"
                                : weightedRiskLevel <= 1.5
                                ? "Low"
                                : weightedRiskLevel < 2.5
                                ? "Moderate"
                                : "High";

                        // Return the transformed record
                        return {
                            ...record,
                            Field: record.Field
                                ? record.Field.replace(' (All Fields)', '')
                                : record.Field,
                            positiveROI: record.code_netnpv_count > 0
                                ? (record.code_posnpv_count / record.code_netnpv_count)
                                : "N/A",
                            code_median_netnpv: record.code_netnpv_count > 0
                                ? record.code_median_netnpv
                                : "N/A",
                            code_unemployment_rate: weightedUnemploymentRate !== null
                                ? weightedUnemploymentRate
                                : "N/A",
                            code_underemployment_rate: weightedUnderemploymentRate !== null
                                ? weightedUnderemploymentRate
                                : "N/A",
                            code_overall_risk: overallRisk,
                            cipcodes: mergedCipcodes // Use the merged cipcodes
                        };
                    })
                    .filter(record => record.code_netnpv_count >= 5)
                    .sort((a, b) => b.code_netnpv_count - a.code_netnpv_count);

                setData(transformedData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const sortedData = React.useMemo(() => {
        if (sortConfig.key) {
            return [...data]
                .map((record) => {
                    // Dynamically map the sortConfig.key for CIPCODE rows
                    const cipKey =
                        sortConfig.key === 'code_netnpv_count'
                            ? 'cip_netnpv_count'
                            : sortConfig.key === 'code_median_netnpv'
                            ? 'cip_median_netnpv'
                            : sortConfig.key === 'code_overall_risk'
                            ? 'risk_level'
                            : sortConfig.key === 'Field'
                            ? 'CIPDESC'
                            : sortConfig.key === 'code_unemployment_rate'
                            ? 'UNEMPLOYMENT_RATE'
                            : sortConfig.key;

                    // Sort CIPCODE rows
                    const sortedCipcodes = [...record.cipcodes].sort((a, b) => {
                        const aValue = a[cipKey];
                        const bValue = b[cipKey];
    
                        // Handle AI Risk sorting for CIPCODEs
                        if (cipKey === 'risk_level') {
                            const riskOrder = { Low: 1, Moderate: 2, High: 3 };
                            const aRank =
                                aValue in riskOrder
                                    ? riskOrder[aValue]
                                    : sortConfig.direction === 'asc'
                                    ? 999 // N/A last in ascending
                                    : -1; // N/A last in descending
                            const bRank =
                                bValue in riskOrder
                                    ? riskOrder[bValue]
                                    : sortConfig.direction === 'asc'
                                    ? 999
                                    : -1;
                            return sortConfig.direction === 'asc'
                                ? aRank - bRank
                                : bRank - aRank; // Reverse logic for descending
                        }
    
                        // General sorting for other CIPCODE columns
                        if (aValue === "N/A") return 1; // Place N/A at the end
                        if (bValue === "N/A") return -1;
    
                        const multiplier = sortConfig.direction === 'asc' ? 1 : -1;
                        return aValue > bValue ? 1 * multiplier : -1 * multiplier;
                    });
    
                    return {
                        ...record,
                        cipcodes: sortedCipcodes, // Replace with sorted CIPCODEs
                    };
                })
                .sort((a, b) => {
                    const aValue = a[sortConfig.key];
                    const bValue = b[sortConfig.key];
    
                    // Handle AI Risk sorting for CODEs
                    if (sortConfig.key === 'code_overall_risk') {
                        const riskOrder = { Low: 1, Moderate: 2, High: 3 };
                        const aRank =
                            aValue in riskOrder
                                ? riskOrder[aValue]
                                : sortConfig.direction === 'asc'
                                ? 999 // N/A last in ascending
                                : -1; // N/A last in descending
                        const bRank =
                            bValue in riskOrder
                                ? riskOrder[bValue]
                                : sortConfig.direction === 'asc'
                                ? 999
                                : -1;
                        return sortConfig.direction === 'asc'
                            ? aRank - bRank
                            : bRank - aRank; // Reverse logic for descending
                    }
    
                    // General sorting for other CODE columns
                    if (aValue === "N/A") return 1;
                    if (bValue === "N/A") return -1;
    
                    const multiplier = sortConfig.direction === 'asc' ? 1 : -1;
                    return aValue > bValue ? 1 * multiplier : -1 * multiplier;
                });
        }
        return data;
    }, [data, sortConfig]);      

    const toggleRow = (code) => {
        setExpandedRows((prev) => ({
            ...prev,
            [code]: !prev[code] // Toggle the expanded state of the row
        }));
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        }).format(value);
    };

    function formatNumberWithCommas(number) {
        return number ? number.toLocaleString() : "";
    }

    function formatNumberAsPercentage(number, decimalPlaces = 0) {
        if (number !== null && number !== undefined && !isNaN(number)) {
            return `${(number * 100).toFixed(decimalPlaces)}%`;
        }
        return "N/A";
    }

    const handleSort = (key) => {
        setSortConfig((prevConfig) => {
            if (prevConfig.key === key) {
                // Toggle direction if the same key is clicked
                return { key, direction: prevConfig.direction === 'asc' ? 'desc' : 'asc' };
            }
            // Otherwise, set to ascending for the new key
            return { key, direction: 'asc' };
        });
    };
     

    const riskLevelMap = {
        "Low": "🟢",
        "Moderate": "🟡",
        "High": "🔴",
      };

    const pcipEmojiMap = {
        1: "🚜",
        3: "🏞️",
        4: "📐",
        5: "🌈",
        9: "🖋️",
        10: "👄",
        11: "👨‍💻",
        13: "🏫",
        14: "⚙️",
        15: "👩‍🔧",
        16: "🈸",
        19: "👪",
        22: "⚖️",
        23: "🇬🇧",
        24: "🎭",
        26: "🧬",
        27: "🔢",
        30: "📚",
        31: "🚴",
        38: "☯️",
        39: "✝️",
        40: "🚀",
        42: "🧠",
        43: "🔫",
        44: "🦮",
        45: "♟️",
        46: "👷",
        47: "🛠️",
        49: "🚢",
        50: "🎨",
        51: "👩‍⚕️",
        52: "💸",
        54: "🏺"
    }

    function getPCIPEmoji(pcipCode) {
        const codeAsInt = parseInt(pcipCode, 10);
    
        // Check if the pcipCode exists in the map
        if (pcipEmojiMap.hasOwnProperty(codeAsInt)) {
            return pcipEmojiMap[codeAsInt];
        }
    
        // Return a default emoji or null if not found
        return null; // Or return a default emoji like "📚" for not found
    }

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="dashboard-main">
            <h1>College ROI Dashboard</h1>
            <div className="table-container">
                <table className="dashboard-table">
                    <thead>
                        <tr className="dashboard-table-header">
                            <th
                                className="dashboard-table-header-item"
                                onClick={() => handleSort('Field')}
                            >
                                Degree Program {sortConfig.key === 'Field' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                            </th>
                            <th
                                className="dashboard-table-header-item"
                                onClick={() => handleSort('code_median_netnpv')}
                            >
                                Median ROI {sortConfig.key === 'code_median_netnpv' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                            </th>
                            {/* <th className="dashboard-table-header-item">% Positive ROI</th> */}
                            <th
                                className="dashboard-table-header-item"
                                onClick={() => handleSort('code_unemployment_rate')}
                            >
                                Unemploy {sortConfig.key === 'code_unemployment_rate' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                            </th>
                            {/* <th className="dashboard-table-header-item">Underemployment Est.</th> */}
                            <th
                                className="dashboard-table-header-item"
                                onClick={() => handleSort('code_overall_risk')}
                            >
                                AI Risk {sortConfig.key === 'code_overall_risk' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                            </th>
                            <th
                                className="dashboard-table-header-item"
                                onClick={() => handleSort('code_netnpv_count')}
                            >
                                Count {sortConfig.key === 'code_netnpv_count' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((record) => {
                            const isExpanded = expandedRows[record.CODE];
                            return (
                                <React.Fragment key={record.CODE}>
                                    {/* CODE-level row */}
                                    <tr className="dashboard-table-code code-row" onClick={() => toggleRow(record.CODE)} style={{ cursor: 'pointer' }}>
                                        <td>
                                            <span className="dashboard-table-expand" style={{ marginRight: '2px' }}>
                                                {isExpanded ? '▼' : '▶'} {/* Expand/Collapse icon */}
                                            </span>
                                            {getPCIPEmoji(record.CODE)} {record.Field}
                                            <div className="dashboard-table-cipdesc-links">
                                                <a className="dashboard-table-cipdesc-link" href={`/programrankings/?pcip=${record.CODE}&page=1&sort=rank_desc`}> View rankings</a>
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                className="dashboard-table-roi"
                                                style={{
                                                    backgroundColor: 
                                                        record.code_median_netnpv === "N/A" 
                                                            ? 'grey' 
                                                            : record.code_median_netnpv < 0 
                                                            ? '#F92654' 
                                                            : '#05BFDD'
                                                }}
                                            >
                                                {record.code_median_netnpv !== "N/A"
                                                    ? formatCurrency(record.code_median_netnpv)
                                                    : "N/A"}
                                            </div>
                                        </td>
                                        <td>
                                            {record.code_unemployment_rate !== "N/A"
                                                ? formatNumberAsPercentage(record.code_unemployment_rate, 1)
                                                : "N/A"}
                                        </td>
                                        <td>
                                            <div>{riskLevelMap[record.code_overall_risk]}</div>
                                            <div className="dashboard-table-riskdesc">{record.code_overall_risk}</div>
                                        </td>
                                        <td>{formatNumberWithCommas(record.code_netnpv_count)}</td>
                                    </tr>
                                    {/* CIPCODE-level rows (collapsible) */}
                                    {isExpanded && record.cipcodes.map((cip, index) => (
                                        <tr 
                                            key={cip.CIPCODE}
                                            className={`cipcode-row ${index % 2 === 0 ? 'cipcode-row-even' : 'cipcode-row-odd'}`}
                                        >
                                            <td style={{ paddingLeft: '20px' }}>
                                                <div>↳ {cip.CIPDESC.replace(/\./g, '')}</div>
                                                <div className="dashboard-table-cipdesc-links">
                                                    <a className="dashboard-table-cipdesc-link" href={`/programrankings/?pcip=${record.CODE}&cip=${cip.CIPCODE}&page=1&sort=rank_desc`}> View rankings</a> 
                                                    &nbsp;|&nbsp;
                                                    <a className="dashboard-table-cipdesc-link" href={`/majors?DEGREE=${encodeURIComponent(cip.CIPDESC.replace(/[^a-zA-Z0-9 ]/g, ''))}&CIPCODE=${cip.CIPCODE}`}>More Info</a>
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    className="dashboard-table-roi"
                                                    style={{
                                                        backgroundColor: 
                                                            cip.cip_median_netnpv === "N/A" 
                                                                ? 'grey' 
                                                                : cip.cip_median_netnpv < 0 
                                                                ? '#F92654' 
                                                                : '#05BFDD'
                                                    }}
                                                >
                                                    {cip.cip_median_netnpv !== "N/A"
                                                        ? formatCurrency(cip.cip_median_netnpv)
                                                        : "N/A"}
                                                </div>
                                            </td>
                                            <td>
                                                {cip.UNEMPLOYMENT_RATE !== undefined
                                                    ? formatNumberAsPercentage(cip.UNEMPLOYMENT_RATE, 1)
                                                    : "N/A"}
                                            </td>
                                            <td>
                                                <div>{riskLevelMap[cip.risk_level]}</div>
                                                <div className="dashboard-table-riskdesc">{cip.risk_level}</div>
                                            </td>
                                            <td>{formatNumberWithCommas(cip.cip_netnpv_count)}</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <AdResponsiveHorizontal />
        </div>
    );
}
