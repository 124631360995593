export default function CollegePageDescription ({
    currName,
    currAlias,
    currRegion,
    currLocale,
    currCity,
    currState,
    currType,
    currDeg,
    currUGDS
}) {

    return (
        <div>
            <h3>Description</h3>
                <p>
                    {currName}
                        {currAlias && (
                            <span>, also known as {currAlias},</span>
                        )} 
                    &nbsp;is a {currType} {currDeg} located in {currRegion} {currLocale} {currCity}, {currState}.         
                    {currUGDS && currAlias && (
                        <span>&nbsp;{currAlias} currently has {currUGDS} enrolled undergraduate students.</span>
                    )}
                    {currUGDS && !currAlias && (
                        <span>&nbsp;{currName} currently has {currUGDS} enrolled undergraduate students.</span>
                    )}           
                </p>
        </div>
    );
}