import React, { useEffect, useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "./UserContext";

const Return = () => {
    const [status, setStatus] = useState(null);
    const { refreshUserInfo } = useContext(UserContext);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        const handleTierUpgrade = async () => {
            try {
                // Check session status
                const sessionResponse = await fetch(
                    `${process.env.REACT_APP_API_URL}/session-status?session_id=${sessionId}`
                );
                const sessionData = await sessionResponse.json();

                setStatus(sessionData.status);

                if (sessionData.status === "complete") {
                    // Confirm the upgrade
                    const upgradeResponse = await fetch(
                        `${process.env.REACT_APP_API_URL}/confirm-upgrade`,
                        {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            credentials: "include", // Include cookies for authentication
                            body: JSON.stringify({ sessionId }),
                        }
                    );

                    if (upgradeResponse.ok) {
                        await refreshUserInfo(); // Refresh user data after successful upgrade
                    } else {
                        console.error('Error confirming upgrade:', await upgradeResponse.json());
                    }
                }
            } catch (error) {
                console.error('Error handling tier upgrade:', error);
            }
        };

        if (sessionId) {
            handleTierUpgrade();
        }
    }, [refreshUserInfo]);

    if (status === "complete") {
        return <Navigate to="/collegeadvisor" />;
    }

    return <div>Loading payment status...</div>;
};

export default Return;
