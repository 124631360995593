import { useNavigate } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';
import { UserContext } from "./UserContext";

export default function CollegePageCard ({
    INSTNM,
    UNITID,
    currAlias,
    currName,
    currURL,
    currRank
}) {

    const apiUrl = process.env.REACT_APP_API_URL || '';
    const blobUrl = process.env.REACT_APP_BLOB_URL || 'https://collegenpvcloudstoredev.blob.core.windows.net/images';
    const imageUrl = `${UNITID}.jpg`;

    const [isAdded, setIsAdded] = useState(false);

    useEffect(() => {
        // Fetch saved colleges on component mount to check if this college is already added
        const fetchSavedColleges = async () => {
            try {
                const response = await fetch(`${apiUrl}/get-saved-colleges`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                });

                if (response.ok) {
                    const savedColleges = await response.json();
                    // Check if this UNITID exists in the saved colleges
                    const isAlreadyAdded = savedColleges.some(college => Number(college.UNITID) === Number(UNITID));
                    setIsAdded(isAlreadyAdded);
                } else {
                    //console.error('Failed to fetch saved colleges');
                }
            } catch (error) {
                console.error('Error fetching saved colleges:', error);
            }
        };

        fetchSavedColleges();
    }, [UNITID, apiUrl]);

    function formatURL(url) {
        if (!/^https?:\/\//i.test(url)) {
          return `http://${url}`;
        }
        return url;
    };

    const navigate = useNavigate();

    const navigateAndScrollToTop = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    const { userInfo } = useContext(UserContext);

    const handleCardPlusClick = async () => {
        if (!userInfo) {
            navigateAndScrollToTop('/collegeadvisor');
        } else if (isAdded) {
            navigateAndScrollToTop('/collegeadvisor');
        } else if (!isAdded) {
            try {
                const response = await fetch(`${apiUrl}/save-college`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include', // Important for sending cookies with the request
                    body: JSON.stringify({ UNITID }),
                });
    
                if (response.ok) {
                    console.log("College saved successfully");
                    setIsAdded(true); // Update the button state immediately
                } else {
                    console.error('Failed to save college. Please try again.');
                }
            } catch (error) {
                console.error('Error saving college:', error);
            }
        }
    };    

    return (
        <div className="cp-container-csp">
            <div className="cp-card">
                <div className="cp-card-top">
                    <div className="cp-card-ranking">
                        {currRank !== null ? `#${currRank} in expected outcomes` : "Unranked"}
                    </div>
                    <div>
                        {!userInfo ? (
                            <button className="card-plus" onClick={() => handleCardPlusClick(UNITID)}>
                                Sign Up
                            </button>
                        ) : (
                            <button
                                className={`card-plus ${isAdded ? 'added' : ''}`}
                                onClick={() => handleCardPlusClick(UNITID)}
                            >
                                {isAdded ? "Saved" : "+ My List"}
                            </button>
                        )}
                    </div>
                </div>
                <img className="cp-card-img" 
                            src={`${blobUrl}/${imageUrl}`} 
                            alt={`${INSTNM} Image`}
                            onError={(e) => {
                                if (e.target.src !== `${blobUrl}/default.jpg`) {
                                    e.target.onerror = null; // Prevent further triggers of onError
                                    e.target.src = `${blobUrl}/default.jpg`; // Fallback image path
                                }
                            }}
                />
                {currAlias && !currAlias.includes(' ') ? ( //Only one word aliases
                    <h2 className="cp-card-college">{currName} <span>({currAlias})</span></h2>
                ) : (
                    <h2 className="cp-card-college">{currName}</h2>
                )}
                {/* {currURL && (
                    <a className="csp-link" href={formatURL(currURL)} target="_blank">
                        Visit Site
                    </a>
                )} */}
            </div>
        </div>
    );
}