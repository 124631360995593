import React from 'react';
import { useState, useEffect } from 'react';

const CLBConfirmationModal = ({
        show,
        onClose,
        onRemove,
        UNITID,
        INSTNM
    }) => {

    if (!show) {
        return null;
      };

return (
    <div className="modal-overlay">
        <div className="modal-content">
            <button className="close-button" onClick={onClose}>&times;</button>
            <h2>Confirmation</h2>
            <div>Are you sure you want to remove <strong>{INSTNM}</strong> from your list?</div>
            <div className="button-container">
                <button className="clb-modal-cancel" onClick={onClose}>Cancel</button>
                <button className="clb-modal-remove" onClick={onRemove}>Remove</button>
            </div>
        </div>
    </div>
    );
};

export default CLBConfirmationModal;