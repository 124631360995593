function mapNetCostToTerm(netCost) {
    let term;

    if (netCost < 7500) {
        term = 10;
    } else if (netCost < 10000) {
        term = 12;
    } else if (netCost < 20000) {
        term = 15;
    } else if (netCost < 40000) {
        term = 20;
    } else if (netCost < 60000) {
        term = 25;
    } else {
        term = 30;
    }

    return term;
}

function calculateLoanPayment(loanAmount, annualInterestRate, termYears) {
    const monthlyInterestRate = annualInterestRate / 12;
    const totalPayments = termYears * 12;

    const monthlyPayment = 
        loanAmount * 
        (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments)) / 
        (Math.pow(1 + monthlyInterestRate, totalPayments) - 1);

    return monthlyPayment;
}

function calculateNPV(cashFlows, discountRate) {
    let npv = 0;
    for (let t = 0; t < cashFlows.length; t++) {
        npv += cashFlows[t] / Math.pow(1 + discountRate, t + 1);
    }
    return npv;
}


function NPVCalc(collegeGrowth, startingIncome, gradTime, netCost) {
    //Global variables
    const discountRate = .055;
    const hsePV = 592509;
    const employmentYears = 40;
    const loanRate = .055;
    const pmtCap = .1;

    //Calc loan variables
    const term = mapNetCostToTerm(netCost);
    const monthlyPayment = calculateLoanPayment(netCost, loanRate, term);
    const totalPayments = term*12;

    //Init cash flow variables
    const cashFlows = [];
    let currentIncome = 0;
    let currentPmt = 0;
    let currentCashFlow = 0;
    let periodPayments = 0;
    let paymentCounter = 0;
    
    for (let year = 1; year <= employmentYears; year++) {
        if (year >= gradTime + 1) {
            if (year === gradTime + 1) {
                currentIncome = startingIncome;
                currentPmt = Math.min(monthlyPayment, currentIncome/12*pmtCap);
                periodPayments = 6;
                currentCashFlow = currentIncome - (currentPmt*periodPayments);
            } else {
                currentIncome *= (1 + collegeGrowth);
                currentPmt = Math.min(monthlyPayment, currentIncome/12*pmtCap);
                if (totalPayments - paymentCounter < 12) {
                    periodPayments = Math.max(totalPayments - paymentCounter,0);
                } else if (totalPayments - paymentCounter >= 12) {
                    periodPayments = 12;
                } else {
                    periodPayments = 0;
                }
                currentCashFlow = currentIncome - (currentPmt*periodPayments);
            }
        }
        cashFlows.push(currentCashFlow);
        paymentCounter = paymentCounter + periodPayments;
    }

    const NPV = calculateNPV(cashFlows, discountRate)

    return { cashFlows, NPV, hsePV, term, monthlyPayment, totalPayments };
}

// Export the function for use in other modules
module.exports = NPVCalc;