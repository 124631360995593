import React, { useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
stripePromise.then((stripe) => {
    console.log("Stripe initialized:", stripe);
  });

const CheckoutForm = () => {
    const fetchClientSecret = useCallback(async () => {
        // Dynamically fetch the client secret from your backend
        const res = await fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include", // Include cookies for authentication
        });
        const data = await res.json();
        return data.clientSecret;
    }, []);

    const options = { fetchClientSecret };

    return (
        <div id="checkout">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    );
};

export default CheckoutForm;
