export default function CollegePageStudies ({
    currName,
    currAlias,
    PCIP01,
    PCIP03,
    PCIP04,
    PCIP05,
    PCIP09,
    PCIP10,
    PCIP11,
    PCIP12,
    PCIP13,
    PCIP14,
    PCIP15,
    PCIP16,
    PCIP19,
    PCIP22,
    PCIP23,
    PCIP24,
    PCIP25,
    PCIP26,
    PCIP27,
    PCIP29,
    PCIP30,
    PCIP31,
    PCIP38,
    PCIP39,
    PCIP40,
    PCIP41,
    PCIP42,
    PCIP43,
    PCIP44,
    PCIP45,
    PCIP46,
    PCIP47,
    PCIP48,
    PCIP49,
    PCIP50,
    PCIP51,
    PCIP52,
    PCIP54
}) {

    const pctBusiness = PCIP52;
    const pctLiberalArts = PCIP05 + PCIP09 + PCIP13 + PCIP16 + PCIP19 + PCIP22 + PCIP23 + PCIP24 + PCIP25 + PCIP30 + PCIP31 + PCIP38 + PCIP39 + PCIP42 + PCIP44 + PCIP45 + PCIP50 + PCIP54;
    const pctSTEM = PCIP01 + PCIP03 + PCIP10 + PCIP11 + PCIP14 + PCIP15 + PCIP26 + PCIP27 + PCIP29 + PCIP40 + PCIP41 + PCIP51;
    const pctTrade = PCIP04 + PCIP12 + PCIP43 + PCIP46 + PCIP47 + PCIP48 + PCIP49;

    const PCIPMap = {
        "PCIP01": "Agriculture, Agriculture Operations, and Related Sciences",
        "PCIP03": "Natural Resources and Conservation",
        "PCIP04": "Architecture and Related Services",
        "PCIP05": "Area, Ethnic, Cultural, Gender, and Group Studies",
        "PCIP09": "Communication, Journalism, and Related Programs",
        "PCIP10": "Communications Technologies/Technicians and Support Services",
        "PCIP11": "Computer and Information Sciences and Support Services",
        "PCIP12": "Personal and Culinary Services",
        "PCIP13": "Education",
        "PCIP14": "Engineering",
        "PCIP15": "Engineering Technologies and Engineering-Related Fields",
        "PCIP16": "Foreign Languages, Literatures, and Linguistics",
        "PCIP19": "Family and Consumer Sciences/Human Sciences",
        "PCIP22": "Legal Professions and Studies",
        "PCIP23": "English Language and Literature/Letters",
        "PCIP24": "Liberal Arts and Sciences, General Studies and Humanities",
        "PCIP25": "Library Science",
        "PCIP26": "Biological and Biomedical Sciences",
        "PCIP27": "Mathematics and Statistics",
        "PCIP29": "Military Technologies and Applied Sciences",
        "PCIP30": "Multi/Interdisciplinary Studies",
        "PCIP31": "Parks, Recreation, Leisure, and Fitness Studies",
        "PCIP38": "Philosophy and Religious Studies",
        "PCIP39": "Theology and Religious Vocations",
        "PCIP40": "Physical Sciences",
        "PCIP41": "Science Technologies/Technicians",
        "PCIP42": "Psychology",
        "PCIP43": "Homeland Security, Law Enforcement, Firefighting and Related Protective Services",
        "PCIP44": "Public Administration and Social Service Professions",
        "PCIP45": "Social Sciences",
        "PCIP46": "Construction Trades",
        "PCIP47": "Mechanic and Repair Technologies/Technicians",
        "PCIP48": "Precision Production",
        "PCIP49": "Transportation and Materials Moving",
        "PCIP50": "Visual and Performing Arts",
        "PCIP51": "Health Professions and Related Programs",
        "PCIP52": "Business, Management, Marketing, and Related Support Services",
        "PCIP54": "History",
      };

    const businessPCIPCodes = ['PCIP52'];
    const liberalArtsPCIPCodes = [
        'PCIP05', 'PCIP09', 'PCIP13', 'PCIP16', 'PCIP19',
        'PCIP22', 'PCIP23', 'PCIP24', 'PCIP25', 'PCIP30',
        'PCIP31', 'PCIP38', 'PCIP39', 'PCIP42',
        'PCIP44', 'PCIP45', 'PCIP50', 'PCIP54'
    ];    
    const stemPCIPCodes = ['PCIP01', 'PCIP03', 'PCIP10', 'PCIP11', 'PCIP14', 'PCIP15', 'PCIP26', 'PCIP27', 'PCIP29', 'PCIP40', 'PCIP41', 'PCIP51'];
    const tradePCIPCodes = [
        'PCIP04', 'PCIP12', 'PCIP43', 'PCIP46', 'PCIP47',
        'PCIP48', 'PCIP49'
    ];
    

    const pcipValues = {
        PCIP01,
        PCIP03,
        PCIP04,
        PCIP05,
        PCIP09,
        PCIP10,
        PCIP11,
        PCIP12,
        PCIP13,
        PCIP14,
        PCIP15,
        PCIP16,
        PCIP19,
        PCIP22,
        PCIP23,
        PCIP24,
        PCIP25,
        PCIP26,
        PCIP27,
        PCIP29,
        PCIP30,
        PCIP31,
        PCIP38,
        PCIP39,
        PCIP40,
        PCIP41,
        PCIP42,
        PCIP43,
        PCIP44,
        PCIP45,
        PCIP46,
        PCIP47,
        PCIP48,
        PCIP49,
        PCIP50,
        PCIP51,
        PCIP52,
        PCIP54
    };

    const stemPrograms = stemPCIPCodes
        .map(code => ({
            code,
            name: PCIPMap[code],
            value: pcipValues[code]
        }))
        .filter(program => program.value > 0)
        .sort((a, b) => b.value - a.value);

    const businessPrograms = businessPCIPCodes
        .map(code => ({
            code,
            name: PCIPMap[code],
            value: pcipValues[code]
        }))
        .filter(program => program.value > 0)
        .sort((a, b) => b.value - a.value);

    const liberalArtsPrograms = liberalArtsPCIPCodes
        .map(code => ({
            code,
            name: PCIPMap[code],
            value: pcipValues[code] // Using the constructed pcipValues object
        }))
        .filter(program => program.value > 0)
        .sort((a, b) => b.value - a.value);
    
    const tradePrograms = tradePCIPCodes
        .map(code => ({
            code,
            name: PCIPMap[code],
            value: pcipValues[code] // Using the constructed pcipValues object
        }))
        .filter(program => program.value > 0)
        .sort((a, b) => b.value - a.value);



    function formatPercentage(number, decimalPlaces) {
        if (number !== null && number !== undefined) {
            return `${(number * 100).toFixed(decimalPlaces)}%`;
        }
        return "N/A";
    };

    function determineCurriculumType(pctBusiness, pctLiberalArts, pctSTEM, pctTrade) {
        let output = "has a diverse, multi-disciplinary curriculum.";
      
        if (pctBusiness === 1) {
            output = `is a business school.`;
          } else if (pctLiberalArts === 1) {
            output = `is a liberal arts school.`;
          } else if (pctSTEM === 1) {
            output = `is a STEM school.`;
          } else if (pctTrade === 1) {
            output = `is a trade school.`;
          } else {
        
        if (pctBusiness > 0.75) {
          output = `is predominantly a business school, with ${formatPercentage(pctBusiness, 1)} of students studying business.`;
        } else if (pctLiberalArts > 0.75) {
          output = `is predominantly focused on liberal arts, with ${formatPercentage(pctLiberalArts, 1)} of students studying Liberal Arts.`;
        } else if (pctSTEM > 0.75) {
          output = `is predominantly a STEM school, with ${formatPercentage(pctSTEM, 1)} of students studying Science, Technology, Engineering or Math.`;
        } else if (pctTrade > 0.75) {
          output = `is predominantly a trade school, with ${formatPercentage(pctTrade, 1)} of students studying trades.`;
        } else {
          // Check for the highest percentage if no single category dominates
          const maxPct = Math.max(pctBusiness, pctLiberalArts, pctSTEM, pctTrade);
          if (maxPct === pctBusiness && pctBusiness > 0.5) {
            output = `has a strong emphasis on business, with ${formatPercentage(pctBusiness, 1)} of students studying business.`;
          } else if (maxPct === pctLiberalArts && pctLiberalArts > 0.5) {
            output = `has a strong emphasis on liberal arts, with ${formatPercentage(pctLiberalArts, 1)} of students studying Liberal Arts.`;
          } else if (maxPct === pctSTEM && pctSTEM > 0.5) {
            output = `has a strong emphasis on STEM, with ${formatPercentage(pctSTEM, 1)} of students studying Science, Technology, Engineering or Math.`;
          } else if (maxPct === pctTrade && pctTrade > 0.5) {
            output = `has a strong emphasis on trades, with ${formatPercentage(pctTrade, 1)} of students studying trades.`;
          }
        }
    }
        return output;
    };

    return (
        <div>
            {currAlias && !currAlias.includes(' ') ? ( //Only one word aliases
                        <h3 className="cp-valuationtitle">Popular Majors at {currAlias}</h3>
                    ) : (
                        <h3 className="cp-valuationtitle">Popular Majors at {currName}</h3>
            )}
                <div>{currName} {determineCurriculumType(pctBusiness, pctLiberalArts, pctSTEM, pctTrade)}</div>
                {stemPrograms.length > 0 && (
                <div>
                    <h4 className="program-title">Science, Technology, Engineering, and Math ({formatPercentage(pctSTEM, 1)})</h4>
                    <div className="programs">
                            {stemPrograms.map(({ code, name, value }) => (
                                <div key={code}>
                                    <div className="program-major">{name}: <span className="program-major-value"><strong>{formatPercentage(value, 1)}</strong></span></div>
                                </div>
                            ))}
                    </div>
                </div>
                )}
                {liberalArtsPrograms.length > 0 && (
                <div>
                    <h4 className="program-title">Liberal Arts ({formatPercentage(pctLiberalArts, 1)})</h4>
                    <div className="programs">
                            {liberalArtsPrograms.map(({ code, name, value }) => (
                                <div key={code}>
                                    <div className="program-major">{name}: <span><strong>{formatPercentage(value, 1)}</strong></span></div>
                                </div>
                            ))}
                    </div>
                </div>
                )}
                {businessPrograms.length > 0 && (
                <div>
                    <h4 className="program-title">Business ({formatPercentage(pctBusiness, 1)})</h4>
                    <div className="programs">
                        {businessPrograms.map(({ code, name, value }) => (
                            <div key={code}>
                                <div className="program-major">{name}: <span><strong>{formatPercentage(value, 1)}</strong></span></div>
                            </div>
                        ))}
                    </div>
                </div>
                )}
                {tradePrograms.length > 0 && (
                <div>
                    <h4 className="program-title">Trades ({formatPercentage(pctTrade, 1)})</h4>
                    <div className="programs">
                        {tradePrograms.map(({ code, name, value }) => (
                            <div key={code}>
                                <div className="program-major">{name}: <span><strong>{formatPercentage(value, 1)}</strong></span></div>
                            </div>
                        ))}
                    </div>
                </div>
                )}
        </div>
    );
}