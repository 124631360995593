import React, { useEffect, useState } from 'react';
import CollegeRank from './collegerankv3';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function ProductDescriptionSection({
    getStarted
    }) {

    const apiUrl = process.env.REACT_APP_API_URL || '';
    const blobUrl = process.env.REACT_APP_BLOB_URL || 'https://collegenpvcloudstoredev.blob.core.windows.net/images';

    const [colleges,setColleges] = useState([]);

    useEffect(() => {
        const pageNum = 1;
        const searchQueryFromURL = 'Harvard University';
        const sortOption = 'rank_desc';

        const fetchUrl = `${apiUrl}/college?page=${pageNum}&query=${encodeURIComponent(searchQueryFromURL)}&sort=${sortOption}`;

        fetch(fetchUrl)
        .then(response => response.json())
        .then(collegeres => {
            setColleges(collegeres.results);
            //console.log(colleges);
        })
        .catch(error => {
            console.error("Error fetching data:", error);
        });
        //console.log(`URL Parameters: Page = ${pageNum}, Query = ${searchQueryFromURL}, State = ${selectedStateFromURL}`);
    }, [apiUrl]);

    const [activeTab, setActiveTab] = useState('costs'); // Default active tab

    const renderTabContent = () => {
        switch (activeTab) {
            case 'costs':
                return (
                    <div className="tab-content">
                        <h3>Costs of attendance</h3>
                        <p>Estimate the cost of attending schools on your list.</p>
                        <LazyLoadImage className="fos-card-img" 
                                    src={`${blobUrl}/product-costs.png`} 
                                    alt={`Estimate your costs of attending college`}
                                    effect="blur"
                        />
                    </div>
                );
            case 'loans':
                return (
                    <div className="tab-content">
                        <h3>Paying for college</h3>
                        <p>Estimate how much of your costs are covered by scholarships, family contributions and work study.</p>
                        <LazyLoadImage className="fos-card-img" 
                                    src={`${blobUrl}/product-shortfall.png`} 
                                    alt={`Estimate your costs of attending college`}
                                    effect="blur"
                        />
                    </div>
                );
            case 'outcomes':
                return (
                    <div className="tab-content">
                        <h3>Loans and outcomes</h3>
                        <p>Forecast student and parent loans and estimate student loan affordability by field of study.</p>
                        <LazyLoadImage className="fos-card-img" 
                                    src={`${blobUrl}/product-loan.png`} 
                                    alt={`Estimate your costs of attending college`}
                                    effect="blur"
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className="collegeadvisor-card">
                <div className="collegeadvisor-headercontainer">
                    <h2 className="collegeadvisor-header">Build your list of reach, target and safety schools</h2>
                </div>
                <p className="collegeadvisor-description">Browse ROI rankings and filter by geography, selectivity and program offerings to build your school list.</p>
                <div className="collegeadvisor-product-wrapper">
                    {colleges.length > 0 && colleges.map((college) => (
                        <CollegeRank key={college._id} {...college} />
                    ))}
                </div>
            </div>
            <div className="collegeadvisor-card">
                <div className="collegeadvisor-headercontainer">
                    <h2 className="collegeadvisor-header">Estimate costs, loans and outcomes at your schools</h2>
                </div>
                <p className="collegeadvisor-description">Personalize the factors that impact your costs of attending, federal aid and payment options.</p>
                <div className="tab-container">
                    <div className="tab-buttons">
                        <button
                            className={`tab-button ${activeTab === 'costs' ? 'active' : ''}`}
                            onClick={() => setActiveTab('costs')}
                        >
                            Costs
                        </button>
                        <button
                            className={`tab-button ${activeTab === 'loans' ? 'active' : ''}`}
                            onClick={() => setActiveTab('loans')}
                        >
                            Payments
                        </button>
                        <button
                            className={`tab-button ${activeTab === 'outcomes' ? 'active' : ''}`}
                            onClick={() => setActiveTab('outcomes')}
                        >
                            Loans
                        </button>
                    </div>
                    <div className="tab-content-container">{renderTabContent()}</div>
                    <button className="collegeadvisor-button" onClick={getStarted}>
                            Get Started
                    </button>
                </div>
            </div>
        </>
    );
};
