import React, { useEffect, useState } from 'react';
import { useParams,useLocation,useNavigate } from "react-router-dom";
import useDocumentTitle from './useDocumentTitle';

const CollegeMajors = () => {
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const location = useLocation();
  const navigate = useNavigate();
  function useQuery() {
      return new URLSearchParams(location.search);
  }
  const query = useQuery();
  const cip_code = query.get("CIPCODE");
  const DEGREE = query.get("DEGREE");
  useDocumentTitle(DEGREE);
  
  const [majorData, setMajorData] = useState(null);
  const [relatedMajors, setRelatedMajors] = useState([]);
  const [majorStats, setMajorStats] = useState([]);
  const [pctWorth, setPctWorth] = useState();

  useEffect(() => {
    // Define a function to fetch the major data
    const fetchMajorData = async () => {
      try {
        const response = await fetch(`${apiUrl}/majors/${cip_code}`);
        if (!response.ok) {
          navigate('/pagenotfound');
        }
        const data = await response.json();
        if (!data || Object.keys(data).length === 0) {
          navigate('/pagenotfound');
        } else {
          setMajorData(data); // Update the state with the fetched data
          // Now fetch related majors using the PCIP code from the fetched major data
          const relatedResponse = await fetch(`${apiUrl}/majors/related/${data.pcip_code}`);
          if (relatedResponse.ok) {
            const relatedData = await relatedResponse.json();
            const filteredRelatedMajors = relatedData.filter(
              (relatedMajor) => relatedMajor.cip_code !== data.cip_code
            );
            
            setRelatedMajors(filteredRelatedMajors); // Update state with related majors data
          } else {
            console.error('No related majors found');
          }

          // Fetch statistics for the current cip_code
          const statsResponse = await fetch(`${apiUrl}/majors/stats/${cip_code}`);
          if (statsResponse.ok) {
            const statsData = await statsResponse.json();
            setMajorStats(statsData); // Log the statistics data
            setPctWorth(statsData.countWithPositiveNET_NPV/statsData.countWithNET_NPV);
          } else {
            console.error('Failed to fetch statistics');
          }
        }
      } catch (error) {
        console.error('Error fetching major data:', error);
      }
    };

    if (cip_code) {
      fetchMajorData(); // Call the function if cip_code is defined
    }
  }, [cip_code]);

  function useArticle(word) {
    if (!word) return "a"; // Default to "a" if no word is provided
    const firstLetter = word[0].toLowerCase();
    const vowels = ["a", "e", "i", "o", "u"];
    return vowels.includes(firstLetter) ? "an" : "a";
  }  

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    }).format(value);
  };

  function formatPercentage(number, decimalPlaces) {
    if (typeof number === "number") {
        return `${(number * 100).toFixed(decimalPlaces)}%`;
    }
    return "N/A";
  };

  const riskLevelMap = {
    "Low": "🟢 Low",
    "Moderate": "🟡 Moderate",
    "High": "🔴: High",
  };

  const riskLevelMapDescription = {
    "Low": "Job prospects from this program are unlikely to be impacted by AI or automation.",
    "Moderate": "Job prospects from this program could be impacted by AI or automation.",
    "High": "Job prospects from this program are likely to be impacted by AI or automation.",
  };


  return (
    <div className="major-main">
      <h1>Is {useArticle(majorData?.major)} {majorData?.major} Degree Worth It?</h1>
      <div className="major-worthit">
        <strong><span className="major-highlight">{formatPercentage(pctWorth,1)} of {majorData?.major.toLowerCase()} programs ranked by CollegeNPV are worth the investment for the median student, with an average estimated ROI of {formatCurrency(majorStats.averageNET_NPV)}.</span></strong>
        <div className="major-worthit-disclaimer">For more information, read our <a className="major-methodology-link" href={'/methodology'}>methodology</a>.</div> 
        <img src={`${apiUrl}/collegeimages//cnpv_favicon.png`} alt="Brand Logo" className="major-brandmark" />
      </div>
      
      <button 
        className="major-button" 
        onClick={() => window.location.href = `/programrankings/?pcip=${majorData?.pcip_code}&cip=${majorData?.cip_code}&page=1&sort=rank_desc`}
        >View {majorData?.major} Rankings</button>
      <h2>What is {useArticle(majorData?.major)} {majorData?.major} Degree?</h2>
      {majorData?.overview?.description?.map((desc, index) => (
        <p key={index}>{desc}</p>
      ))}
      <h2>What can you do with {useArticle(majorData?.major)} {majorData?.major} Degree?</h2>
      {majorData?.career_paths?.description?.map((desc, index) => (
        <p key={index}>{desc}</p>
      ))}
      <h2>{majorData?.automation_risk?.title} of {useArticle(majorData?.major)} {majorData?.major} Degree</h2>
      <div className="major-risklevel">{riskLevelMap[majorData?.automation_risk?.risk_level] || ''}
        <div className="major-risklevel-desc">{riskLevelMapDescription[majorData?.automation_risk?.risk_level] || ''}</div>
      </div>
      {majorData?.automation_risk?.description?.map((desc, index) => (
        <p key={index}>{desc}</p>
      ))}

      <h2>Related Majors</h2>
        {relatedMajors.length > 0 ? (
          <ul>
            {relatedMajors.map((relatedMajor, index) => (
              <li key={index}>
                <strong><a href={`/majors?DEGREE=${encodeURIComponent(relatedMajor.major.replace(/[^a-zA-Z0-9 ]/g, ''))}&CIPCODE=${relatedMajor.cip_code}`}>{relatedMajor.major}</a></strong>
              </li>
            ))}
          </ul>
        ) : (
          <p>No related majors found.</p>
        )}
    </div>
  );
};

export default CollegeMajors;
