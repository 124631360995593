import React from 'react';

const Pagination = ({ page, totalPages, onPageChange }) => {
  // Limits the number of pages in the pagination display
  const pageLimit = 4; // Adjust this number based on your preference
  let startPage = page - Math.floor(pageLimit / 2);
  let endPage = page + Math.floor(pageLimit / 2);

  // Ensure startPage and endPage are within available range
  if (startPage < 1) {
    endPage += 1 - startPage;
    startPage = 1;
  }
  if (endPage > totalPages) {
    startPage -= endPage - totalPages;
    endPage = totalPages;
  }

  // Ensure startPage is always at least 1
  startPage = Math.max(startPage, 1);

  // Create an array of page numbers for rendering
  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return (
    <div className="pagination-container">
      {/* Previous Button */}
      <button 
        className="prev-button"
        onClick={() => onPageChange(Math.max(1, page - 1))} 
        disabled={page === 1}
      >
        Prev
      </button>

      {/* First Page Button */}
      {startPage > 1 && (
        <button onClick={() => onPageChange(1)}>1</button>
      )}
      {startPage > 2 && <span>...</span>}

      {/* Page Number Buttons */}
      {pages.map(number => (
        <button 
          key={number} 
          onClick={() => onPageChange(number)} 
          className={page === number ? 'active' : ''}
        >
          {number}
        </button>
      ))}

      {/* Last Page Button */}
      {endPage < totalPages - 1 && <span>...</span>}
      {endPage < totalPages && (
        <button onClick={() => onPageChange(totalPages)}>{totalPages}</button>
      )}

      {/* Next Button */}
      <button 
        className="next-button"
        onClick={() => onPageChange(Math.min(totalPages, page + 1))} 
        disabled={page === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;