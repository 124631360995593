import DecisionTreeVisualization from '../DecisionTree.js';
const calcNPV = require('../NPVCalc.js');

export default function AboutPage() {
    const startingIncome = 40000;
    const g = .02;
    const gradTime = 4;
    const netCost = 80000;
    const NPV = calcNPV(g, startingIncome, gradTime, netCost);
    console.log(NPV);
    const barData = [
        {name: "Monthly Income", value: 10000}
    ];

    const barData2 = [
        {name: "Debt Payment", value: 500}
    ];
    
    
    return (
    <div className="about-main">
        <DecisionTreeVisualization />
    </div>        
    );
}