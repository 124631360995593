import React, { useEffect } from 'react';

const AdResponsiveHorizontal = () => {
    useEffect(() => {
        // Check if the domain is collegenpv.com or www.collegenpv.com
        const hostname = window.location.hostname;
        if ((hostname === "collegenpv.com" || hostname === "www.collegenpv.com") && window.adsbygoogle) {
          // Ensure ads are rendered if ad script is already loaded
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
      }, []);
      
      // Render ad code only if on collegenpv.com or www.collegenpv.com
      if (window.location.hostname !== "collegenpv.com" && window.location.hostname !== "www.collegenpv.com") {
        return null; // Return nothing if not on production
      }

  return (
    <div className="ad-container">
      <ins className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-7106493837985405"
        data-ad-slot="6508658559"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div>
  );
};

export default AdResponsiveHorizontal;
