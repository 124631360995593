import React, { useState, useEffect } from 'react';
import FinancialInfoModal from './AdvisorFinancialInfoModal';
import CollegeListBuilder from './pages/CollegeListBuilder';

export default function AdvisorDashboard({ tier, onUpgradeClick }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [familyInfo, setFamilyInfo] = useState(null);
    const [financialInfo, setFinancialInfo] = useState(null);
    const [isFinancialModalOpen, setIsFinancialModalOpen] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL || '';

    const openFinancialModal = () => setIsFinancialModalOpen(true);
    const closeFinancialModal = () => setIsFinancialModalOpen(false);

    useEffect(() => {
        const fetchFamilyInfo = async () => {
            try {
                const response = await fetch(`${apiUrl}/family-info`, {
                    method: 'GET',
                    credentials: 'include', // Send cookies with the request
                });

                if (response.ok) {
                    const data = await response.json();
                    setFamilyInfo(data); // Set fetched data to state
                } else if (response.status === 404) {
                    console.log('No family information found.');
                } else {
                    throw new Error('Failed to fetch family information.');
                }
            } catch (error) {
                console.error('Error fetching family information:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchFamilyInfo();
    }, [apiUrl, isFinancialModalOpen]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div>
            <h1 className="collegeadvisor-header">Welcome to Advisor!</h1>
            {familyInfo && (
                <div className="collegeadvisor-sections">
                    
                    {/* Placeholder for Financial Information Section */}
                    <div 
                        className="collegeadvisor-section-complete"
                        onClick={openFinancialModal} // Open modal on click
                        style={{ cursor: 'pointer' }} // Make it visually clickable
                    >
                        <h3 className="collegeadvisor-section-header-complete">Profile Complete ✔️</h3>
                        <button
                            className="collegeadvisor-button"
                            onClick={openFinancialModal}
                            style={{
                                maxWidth: "125px",
                                marginTop: "0px",
                                marginBottom: "0px",
                                backgroundColor: "#CCCCCC",
                                color: "#000933"
                            }}
                        >
                            Edit profile
                        </button>
                    </div>
                </div>
            )}
            {!familyInfo && (
                <div className="collegeadvisor-sections">
                    
                    {/* Placeholder for Financial Information Section */}
                    <div 
                        className="collegeadvisor-section"
                        onClick={openFinancialModal} // Open modal on click
                        style={{ cursor: 'pointer' }} // Make it visually clickable
                    >
                        <h3 className="collegeadvisor-section-header">⚠️ Complete your profile</h3>
                        <p className="collegeadvisor-section-description">
                            Your profile is used to estimate whether thousands of universities fit your budget.
                        </p>
                        <button
                            className="collegeadvisor-button"
                            onClick={openFinancialModal}
                        >
                        Complete your profile
                        </button>
                    </div>
                </div>
            )}
            <FinancialInfoModal
                isFinOpen={isFinancialModalOpen}
                closeFinModal={closeFinancialModal}
                initialData={familyInfo}
            />
            <CollegeListBuilder 
                tier={tier}
                onUpgradeClick={onUpgradeClick}
                familyInfo={familyInfo}
                openFinancialModal={openFinancialModal}
            />
        </div>
    );
}