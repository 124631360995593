import React from 'react';

export default function RegisterStep({
    email,
    setEmail,
    password,
    setPassword,
    userType,
    setUserType,
    handleRegister,
    setCurrentStep,
    formError,
}) {
    return (
        <>
            <h2 className="collegeadvisor-modal-header">Create Your Free Account</h2>
            <form
                className="collegeadvisor-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleRegister();
                }}
            >
                <select
                    value={userType}
                    onChange={(e) => setUserType(e.target.value)}
                    required
                    className="collegeadvisor-select"
                >
                    <option value="">I am a...</option>
                    <option value="student">I am a student</option>
                    <option value="parent">I am a parent</option>
                </select>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="collegeadvisor-input"
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="collegeadvisor-input"
                />
                {formError && <div className="collegeadvisor-error">{formError}</div>}
                <div className="collegeadvisor-disclosure">
                    By proceeding, I agree to CollegeNPV's&nbsp;
                    <a href="/privacy">Privacy Policy</a>.
                </div>
                <button type="submit" className="collegeadvisor-next-button">
                    Create Account
                </button>
            </form>
            <p className="collegeadvisor-toggle-text">
                Already have an account?{' '}
                <span className="collegeadvisor-link" onClick={() => setCurrentStep(2)}>
                    Login
                </span>
            </p>
        </>
    );
}
