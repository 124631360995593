import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const SearchToggle = () => {
  const location = useLocation(); // Get the current URL path
  const navigate = useNavigate(); // For navigation between pages

  // Determine the active toggle based on the current pathname
  const isCollegesActive = location.pathname.startsWith('/collegerankings');
  const isProgramsActive = location.pathname.startsWith('/programrankings');

  return (
    <div className="search-toggle-wrapper">
      <div
        className={`search-toggle-colleges ${isCollegesActive ? 'active' : ''}`}
        onClick={() => navigate('/collegerankings?query=&page=1&sort=rank_desc')} // Navigate to Colleges page
      >
        Colleges
      </div>
      <div
        className={`search-toggle-programs ${isProgramsActive ? 'active' : ''}`}
        onClick={() => navigate('/programrankings?query=&page=1&sort=rank_desc')} // Navigate to Programs page
      >
        Degree Programs
      </div>
    </div>
  );
};

export default SearchToggle;
