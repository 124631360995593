import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "./UserContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from '../src/cnpv_logo.svg';

export default function Header() {
    const { userInfo, refreshUserInfo } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for the dropdown
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const [hasFetchedProfile, setHasFetchedProfile] = useState(false); // Track if the profile has been fetched

    // Fetch user profile when the Header mounts
    useEffect(() => {
        const fetchProfile = async () => {
            if (!hasFetchedProfile) {
                await refreshUserInfo(); // Fetch user info
                setHasFetchedProfile(true); // Mark profile as fetched
            }
        };
        fetchProfile();
    }, [hasFetchedProfile, refreshUserInfo]); // Only depends on `hasFetchedProfile` and `refreshUserInfo`

    // Redirect logic based on location and authentication
    useEffect(() => {
        if (hasFetchedProfile) {
            if (!userInfo && location.pathname === '/account') {
                navigate('/login'); // Redirect to login if not authenticated
            } else if (userInfo && location.pathname === '/login') {
                navigate('/account'); // Redirect to account if already logged in
            }
        }
    }, [userInfo, location.pathname, navigate, hasFetchedProfile]);

    const logout = async () => {
        try {
            await fetch(`${apiUrl}/logout`, {
                credentials: 'include',
                method: 'POST',
            });
            setHasFetchedProfile(false); // Reset the profile fetch flag after logout
            await refreshUserInfo(); // Refresh user info to clear state
            navigate('/'); // Redirect to home after logout
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const userEmail = userInfo?.email;

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <header>
            <Link to="/" className="logo" aria-label="Visit the home page" onClick={scrollToTop}>
                <Logo alt="CollegeNPV Logo" />
            </Link>
            <div className="header-right">
                <Link to={userEmail ? "/collegeadvisor" : "/collegeadvisor"} className="header-register" onClick={scrollToTop}>
                    {userEmail ? "Profile" : "Register"}
                </Link>
                <div className={`dropdown ${isDropdownOpen ? 'open' : ''}`}>
                    <button className="hamburger" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>☰</button>
                    {isDropdownOpen && (
                        <nav className="dropdown-menu">
                            {userEmail && (
                                <>
                                    <Link to='/collegerankings?query=&page=1&sort=rank_desc' onClick={() => { setIsDropdownOpen(false); scrollToTop(); }}>College Rankings</Link>
                                    <Link to='/programrankings?query=&page=1&sort=rank_desc' onClick={() => { setIsDropdownOpen(false); scrollToTop(); }}>Program Rankings</Link>
                                    <Link to="/collegeadvisor" onClick={() => { setIsDropdownOpen(false); scrollToTop(); }}>College Advisor</Link>
                                    <Link to='/collegeroiheatmap' onClick={() => { setIsDropdownOpen(false); scrollToTop(); }}>College ROI Heatmap</Link>
                                    <Link to='/collegeroidashboard' onClick={() => { setIsDropdownOpen(false); scrollToTop(); }}>College ROI Dashboard</Link>
                                    <Link to="/methodology" onClick={() => { setIsDropdownOpen(false); scrollToTop(); }}>About Us</Link>
                                    <a onClick={() => { logout(); setIsDropdownOpen(false); scrollToTop(); }}>Logout</a>
                                </>
                            )}
                            {!userEmail && (
                                <>
                                    <Link to='/collegerankings?query=&page=1&sort=rank_desc' onClick={() => { setIsDropdownOpen(false); scrollToTop(); }}>College Rankings</Link>
                                    <Link to='/programrankings?query=&page=1&sort=rank_desc' onClick={() => { setIsDropdownOpen(false); scrollToTop(); }}>Program Rankings</Link>
                                    <Link to='/collegeroiheatmap' onClick={() => { setIsDropdownOpen(false); scrollToTop(); }}>College ROI Heatmap</Link>
                                    <Link to='/collegeroidashboard' onClick={() => { setIsDropdownOpen(false); scrollToTop(); }}>College ROI Dashboard</Link>
                                    <Link to="/collegeadvisor" onClick={() => { setIsDropdownOpen(false); scrollToTop(); }}>Register</Link>
                                    <Link to="/collegeadvisor" onClick={() => { setIsDropdownOpen(false); scrollToTop(); }}>Login</Link>
                                    <Link to="/methodology" onClick={() => { setIsDropdownOpen(false); scrollToTop(); }}>About Us</Link>
                                </>
                            )}
                        </nav>
                    )}
                </div>
            </div>
        </header>
    );
}
