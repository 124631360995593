import React from 'react';

const TestimonialsSection = ({getStarted}) => {
    const testimonials = [
        {
            quote: "As a parent, I was shocked when I saw negative ROI for one of the schools my daughter was considering. There are already tons of rankings out there, but no one is telling parents which programs are a waste of money. CollegeNPV helped us avoid a costly mistake.",
            author: "Joe B.",
            desc: "CollegeNPV Parent User",
        },
        {
            quote: "This tool would have been life-changing for me if it had been available in 2002. CollegeNPV’s ability to compare costs, financial aid packages, and potential future earnings makes it an invaluable resource. I’ve been recommending it to every parent I know.",
            author: "Lauren B.",
            desc: "CollegeNPV Parent User"
        },
        {
            quote: "CollegeNPV helped me compare options and calculate the long-term costs and benefits of different schools, helping me choose the school that best fit my goals and budget. Thank you!",
            author: "Nate T.",
            desc: "CollegeNPV Student User"
        },
    ];

    return (
        <div className="collegeadvisor-card">
            <div className="collegeadvisor-headercontainer">
                <h2>What people say about us</h2>
            </div>
            <div className="testimonials-vertical">
                {testimonials.map((testimonial, index) => (
                    <div key={index} className="testimonial">
                        <p>"{testimonial.quote}"</p>
                        <div className="testimonial-name"><strong>- {testimonial.author}</strong></div>
                        <div className="testimonial-desc">{testimonial.desc}</div>
                    </div>
                ))}
            </div>
            <button className="collegeadvisor-button" onClick={getStarted}>
                            Get Started
            </button>
        </div>
    );
};

export default TestimonialsSection;
